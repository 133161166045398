import instance from "./index";

export const list = () => {
  return instance.get("constant/level", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const add = (data) => {
  return instance.post("constant/level", data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const edit = (data, id) => {
  return instance.post("constant/level/" + id, data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const deleteLevel = (id) => {
  return instance.post(
    `constant/level_delete/${id}`,
    {},
    {
      headers: {
        token: localStorage.getItem("token"),
      },
    }
  );
};
