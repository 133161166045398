import instance from "./index";

export const allCountries = () => {
  return instance.get("constant/country", {
    headers: {
      token: localStorage.getItem("token"),
      "Accept-Language": localStorage.getItem("i18nextLng"),
    },
  });
};

export const addCountry = () => {
  return instance.get("constant/country", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const test = () => {
  return instance.get("constant/roles", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
