import {
  Table,
  message,
  Pagination,
  Row,
  Col,
  Select,
  Button,
  Form,
} from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { list } from "../../Api/Rate";
import { allTracher } from "../../Api/Teacher";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function Rate() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("rate.teacherName"),
      dataIndex: "teacher_name",
    },
    {
      title: t("rate.studentName"),
      dataIndex: "student_name",
    },
    {
      title: t("rate.rate"),
      dataIndex: "rate",
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [tracher, setTracher] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [initData] = useState({
    user_id: "",
  });
  const onChangePage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const updateTable = async (data) => {
    console.log(data);
    try {
      setLoading(true);
      if (!data) data = initData;
      const res = await list(page, pageSize, data.user_id);
      const trachers = await allTracher(page, pageSize);
      setTracher(trachers.data.items);
      setData(res.data.items);
      setTotalPage(res.data.pagination.totalElements);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, [page, pageSize]);

  const handleSearch = () => {
    const data = form.getFieldsValue();
    if (!data.user_id) data.user_id = "";
    updateTable(data);
  };

  return (
    <>
      <Form form={form}>
        <Row>
          <Col span={8} offset={1}>
            <Form.Item name="user_id" label={t("rate.user")}>
              <Select>
                {tracher.map((e) => (
                  <Option key={e._id} value={e._id}>
                    {e.first_name + e.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={handleSearch}>
                {t("rate.search")}
              </Button>
              <Button
                style={{ marginInlineStart: "2px" }}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  form.setFieldsValue({
                    user_id: "",
                    package_id: "",
                    isFinish: "",
                  });
                  updateTable();
                }}
              >
                <ClearOutlined />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={false}
      />
      <br />
      <Pagination
        current={page}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onChangePage}
        total={totalPage}
      />
    </>
  );
}
export default Rate;
