import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PropTypes from "prop-types";
function MyCKEditor(props) {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={props.content}
      config={{
        removePlugins: [
          "CKFinderUploadAdapter",
          "MediaEmbed",
          "EasyImage",
          "Image",
          "ImageCaption",
          "ImageStyle",
          "ImageToolbar",
          "ImageUpload",
        ],
      }}
      onInit={(editor) => {
        // You can store the "editor" and use when it is needed.
        // console.log("Editor is ready to use!", editor);
        editor.editing.view.change((writer) => {
          writer.setStyle(
            "height",
            "200px",
            editor.editing.view.document.getRoot()
          );
        });
      }}
      onBlur={(event, editor) => {
        props.contentSetter(editor.getData());
      }}
    />
  );
}
MyCKEditor.propTypes = {
  content: PropTypes.string,
  contentSetter: PropTypes.func,
};
export default MyCKEditor;
