import { Table, message, Pagination } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { adminNotification } from "../../Api/Notification";

function AdminNotification() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("adminNotification.fromName"),
      dataIndex: "fromName",
    },
    {
      title: t("adminNotification.title"),
      dataIndex: "title",
    },
    {
      title: t("adminNotification.msg"),
      dataIndex: "msg",
    },
    {
      title: t("adminNotification.dtDate"),
      dataIndex: "dt_date",
      render: (_) => moment(_).format("YYYY-MM-DD"),
    },
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const onChangePage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    const update = async () => {
      try {
        setLoading(true);
        const res = await adminNotification(page, pageSize);
        setData(res.data.items);
        setTotalPage(res.data.pagination.totalElements);
        setLoading(false);
      } catch (error) {
        message.error(t("errors.server"));
        setLoading(false);
      }
    };
    update();
  }, [page, pageSize]);

  return (
    <>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={false}
      />
      <br />
      <Pagination
        current={page}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onChangePage}
        total={totalPage}
      />
    </>
  );
}
export default AdminNotification;
