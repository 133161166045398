import instance from './index';

export const login = (data) => {
  return instance.post('/admin/login', data);
};
export const isAuth = () => {
  return instance.post('admin/check', {
    token: localStorage.getItem('token'),
  });
};
export const editProfile = (data) => {
  return instance.post('admin/update', data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const logout = (id) => {
  return instance.post(
    'admin/logout/' + id,
    {},
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};
