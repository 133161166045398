import { useContext } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import "./style.css";
import { useHistory, useLocation } from "react-router";
import Authenticate from "../../Utils/Authenticate";
import { useState } from "react";
// import { Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { UserContext } from "./../../context";

function LoginPage() {
  const { updateUser } = useContext(UserContext);
  const { t } = useTranslation();
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/users" } };
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = (values) => {
    setIsLoading(true);
    Authenticate.authenticate(
      values,
      (data) => {
        setIsLoading(false);
        updateUser(data);
        history.replace(from);
      },
      () => {
        setIsLoading(false);
      },
      t
    );
  };

  return (
    <Row id="loginPage" align="middle" justify="center">
      <Col span={20} md={10} lg={6}>
        <h1 className="bar-title">{t("login")}</h1>
        <Form
          name="loginForm"
          labelCol={{ span: 24 }}
          colon={false}
          requiredMark={false}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={t("inputs.eamil")}
            name="email"
            rules={[
              {
                required: true,
                message: t("validation.required"),
              },
              {
                type: "email",
                message: t("validation.eamil"),
              },
            ]}
          >
            <Input placeholder={t("placeholder.eamil")} />
          </Form.Item>

          <Form.Item
            label={t("inputs.password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("validation.required"),
              },
            ]}
          >
            <Input.Password placeholder={t("placeholder.password")} />
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Button
              loading={isLoading}
              className="ant-btn-big"
              type="primary"
              htmlType="submit"
            >
              {t("inputs.login")}
            </Button>
          </Form.Item>
          {/* <p style={{ textAlign: "center" }}>
            Forgot your password? <Link to="/">Contact my agent</Link>
          </p> */}
        </Form>
      </Col>
    </Row>
  );
}
export default LoginPage;
