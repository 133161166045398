import instance from "./index";

export const list = () => {
  return instance.get("constant/static", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const edit = (data, id) => {
  return instance.post("constant/static/" + id, data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
