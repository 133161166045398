import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Spin,
  Space,
  message,
  Tooltip,
  Upload,
  Avatar,
  Tag,
} from "antd";
import ImgCrop from "antd-img-crop";
import { EditFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { home, homePage, edit } from "../../Api/HomePage";
import { useTranslation } from "react-i18next";

function HomePage() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("homePage.icon"),
      dataIndex: "icon",
      render: (_) => <Avatar src={_} />,
    },
    {
      title: t("homePage.enName"),
      dataIndex: "enName",
    },
    {
      title: t("homePage.arName"),
      dataIndex: "arName",
    },
    {
      title: t("homePage.enDescription"),
      dataIndex: "enDescription",
    },
    {
      title: t("homePage.arDescription"),
      dataIndex: "arDescription",
    },
    {
      title: t("homePage.icon"),
      dataIndex: "isActive",
      render: (_, record) => (
        <Tag color={record.isVerify ? "green" : "red"}>
          {record.isVerify ? t("homePage.active") : t("homePage.notActive")}
        </Tag>
      ),
    },
    {
      title: t("homePage.operations"),
      key: "actions",
      width: "fit-content",
      align: "center",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("homePage.editPage")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(record)}
            >
              <EditFilled />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [homePageId, setHomePageId] = useState(null);

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await home();
      setData(res.data.items);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };
  const handleAddOk = async () => {
    form.setFieldsValue({ image: fileList.length === 0 ? "" : "True" });
    try {
      await form.validateFields();
      setModalLoading(true);
      const formData = new FormData();
      const objData = form.getFieldsValue();
      for (let key in objData) {
        formData.append(key, objData[key]);
      }
      if (fileList[0].uid !== -1) formData.set("image", fileList[0]);
      else formData.delete("image");
      await edit(formData, homePageId);
      setAddModalVisible(false);
      message.success(t("homePage.editPageDone"));
      await updateTable();
      setModalLoading(false);
      setFileList([]);
    } catch (error) {
      if (error.response) message.error(t("errors.server"));
      setModalLoading(false);
    }
  };
  const handleAddCancel = () => {
    setAddModalVisible(false);
    setHomePageId(null);
    setFileList([]);
  };
  const handleEdit = async (a) => {
    try {
      const {
        data: { items },
      } = await homePage(a._id);
      console.log(items);
      form.setFieldsValue({
        enName: items.enName,
        arName: items.arName,
        arDescription: items.arDescription,
        enDescription: items.enDescription,
        icon: items.icon,
      });
      setFileList([{ uid: -1, url: items.icon }]);
    } catch (error) {
      message.error(t("errors.server"));
    }
    setHomePageId(a._id);
    setAddModalVisible(true);
  };
  const onChange = ({ fileList: newFileList }) => {
    !newFileList.length && form.setFieldsValue({ image: "" });
    setFileList(newFileList);
  };
  const beforeUpload = (file) => {
    setFileList([...fileList, file]);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    form.setFieldsValue({ image: "True" });
    reader.onloadend = () => {
      file.url = reader.result;
      setFileList([file]);
    };
    return false;
  };
  useEffect(() => updateTable(), []);

  return (
    <>
      <br />
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
      />

      <Modal
        title={t("homePage.editInfo")}
        visible={addModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        destroyOnClose
        centered
        okText={t("homePage.edit")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="enName"
              label={t("homePage.enName")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="arName"
              label={t("homePage.arName")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="arDescription"
              label={t("homePage.arDescription")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="enDescription"
              label={t("homePage.enDescription")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="image"
              label={t("homePage.icon")}
              rules={[{ required: true }]}
            >
              <Input hidden />
              <ImgCrop rotate>
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  beforeUpload={beforeUpload}
                  showUploadList={{ showPreviewIcon: false }}
                >
                  {fileList.length < 1 && "+ Upload"}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default HomePage;
