import instance from "./index";

export const list = (page, pageSize) => {
  return instance.get(`constant/complains?page=${page - 1}&limit=${pageSize}`, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const add = (data, id) => {
  return instance.post(`constant/reply-complains/${id}`, data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
