import {
  Table,
  message,
  Pagination,
  Modal,
  Space,
  Button,
  Tooltip,
  Spin,
  Form,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import { list, add } from "../../Api/Complains";
import moment from "moment";
import { AliwangwangOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

function Complains() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("complains.fullName"),
      dataIndex: "full_name",
    },
    {
      title: t("complains.title"),
      dataIndex: "title",
    },
    {
      title: t("complains.details"),
      dataIndex: "details",
    },
    {
      title: t("complains.email"),
      dataIndex: "email",
    },
    {
      title: t("complains.cityId"),
      dataIndex: "city_id",
      render: (_) => moment(_).format("YYYY-MM-DD"),
    },
    {
      title: t("complains.operations"),
      key: "actions",
      width: "fit-content",
      align: "center",
      render: (_, recordData) => (
        <Space>
          <Tooltip title={t("complains.answer")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleReply(recordData)}
            >
              <AliwangwangOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [replyModalVisible, setReplyModalVisible] = useState(false);
  const [record, setRecord] = useState(false);

  const onChangePage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await list(page, pageSize);
      setData(res.data.items);
      setTotalPage(res.data.pagination.totalElements);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, [page, pageSize]);

  const handleReplyOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      const data = form.getFieldsValue();
      await add(data, record._id);
      setReplyModalVisible(false);
      message.success(t("complains.answerDone"));
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleReplyCancel = () => {
    setReplyModalVisible(false);
  };

  const handleReply = (recordData) => {
    setRecord(recordData);
    setReplyModalVisible(true);
  };

  return (
    <>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={false}
      />
      <br />
      <Pagination
        current={page}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onChangePage}
        total={totalPage}
      />

      <Modal
        title={t("complains.answerForComplain")}
        visible={replyModalVisible}
        onOk={handleReplyOk}
        onCancel={handleReplyCancel}
        destroyOnClose
        centered
        okText={t("complains.send")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="msg"
              label={t("complains.answerForComplain")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default Complains;
