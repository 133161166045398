import instance from "./index";

export const list = () => {
  return instance.get("constant/package", {
    headers: {
      token: localStorage.getItem("token"),
      "Accept-Language": localStorage.getItem("i18nextLng"),
    },
  });
};

export const add = (data) => {
  return instance.post("constant/package", data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const edit = (data, id) => {
  console.log(data, id);
  return instance.post("constant/package/" + id, data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const deleteSub = (id) => {
  return instance.post(
    `constant/package_delete/${id}`,
    {},
    {
      headers: {
        token: localStorage.getItem("token"),
      },
    }
  );
};
