import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Spin,
  Space,
  message,
  Tag,
  Tooltip,
  Descriptions,
  Pagination,
  Col,
  Radio,
  Row,
} from "antd";
import {
  CheckSquareFilled,
  StopOutlined,
  NotificationOutlined,
  InfoOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { list, blockUser, sendNotification, userData } from "../../Api/User";
import Avatar from "antd/lib/avatar/avatar";
import { useTranslation } from "react-i18next";

function OurTeam() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("students.userName"),
      dataIndex: "username",
      width: 100,
    },
    {
      title: t("students.gender"),
      dataIndex: "gender",
      width: 100,
    },
    {
      title: t("students.email"),
      dataIndex: "email",
      width: 100,
    },
    {
      title: t("students.phone"),
      dataIndex: "phone_number",
      width: 100,
    },
    {
      title: t("students.country"),
      dataIndex: "country_id",
      render: (_) => _ && _["arName"],
      width: 100,
    },
    {
      title: t("students.city"),
      dataIndex: "city_id",
      render: (_) => _ && _["arName"],
      width: 100,
    },
    {
      title: t("students.isBlock"),
      dataIndex: "isBlock",
      width: 100,
      render: (_, record) => (
        <Tag color={record.isBlock ? "red" : "green"}>
          {record.isBlock ? "محظور" : "فعال"}
        </Tag>
      ),
    },
    {
      title: t("students.operations"),
      key: "actions",
      align: "center",
      fixed: "right",
      width: 130,
      render: (_, record) => (
        <Space>
          {record.isBlock ? (
            <Tooltip title={t("students.verify")}>
              <Button
                type="primary"
                ghost
                size="small"
                onClick={() => handelBlock(record._id, record.isBlock)}
              >
                <CheckSquareFilled />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("students.blockAcc")}>
              <Button
                size="small"
                danger
                onClick={() => handelBlock(record._id, record.isBlock)}
              >
                <StopOutlined />
              </Button>
            </Tooltip>
          )}
          <Tooltip title={t("students.notificationsSend")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleOpenNotficationModel(record._id)}
            >
              <NotificationOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={t("students.infoStudents")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleOpenInfo(record._id)}
            >
              <InfoOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [notficationModalVisible, setNotficationModalVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({});
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [initData] = useState({ search_field: "email", search_value: "" });

  const onChangePage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const updateTable = async (data) => {
    try {
      setLoading(true);
      if (!data) data = initData;
      const res = await list(page, pageSize, data);
      setData(res.data.items);
      setTotalPage(res.data.pagination.totalElements);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handleNotficationOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      const data = form.getFieldsValue();
      await sendNotification(userId, data);

      setNotficationModalVisible(false);
      message.success(t("students.notificationsSendDone"));
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleNotficationCancel = () => {
    setNotficationModalVisible(false);
    setUserId(null);
  };

  const handleOpenNotficationModel = (id) => {
    setNotficationModalVisible(true);
    setUserId(id);
  };

  const handelBlock = async (id, isBlock) => {
    try {
      await blockUser(id, isBlock);
      updateTable();
      message.success(
        isBlock ? t("students.verifyDone") : t("students.blockAccDone")
      );
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handleInfoCancel = (_id) => {
    setInfoModalVisible(false);
  };

  const handleOpenInfo = async (_id) => {
    setInfoModalVisible(true);
    try {
      const {
        data: { items },
      } = await userData(_id);
      setUser(items);
    } catch (error) {
      console.log(error);
      message.error(t("errors.server"));
    }
  };

  const handleSearch = () => {
    updateTable(form1.getFieldsValue());
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, [page, pageSize]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <>
      <Form form={form1}>
        <Row justify="center">
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              name="search_field"
              label={t("students.searchOption")}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio style={radioStyle} value="username">
                  {t("students.userName")}
                </Radio>
                <Radio style={radioStyle} value="email">
                  {t("students.eamil")}
                </Radio>
                <Radio style={radioStyle} value="phone_number">
                  {t("students.phone")}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="search_value" label={t("students.value")}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={handleSearch}>
                {t("students.search")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={false}
        scroll={{ x: "2000" }}
      />
      <br />
      <Pagination
        current={page}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onChangePage}
        total={totalPage}
      />

      <Modal
        title={t("students.notificationsSend")}
        visible={notficationModalVisible}
        onOk={handleNotficationOk}
        onCancel={handleNotficationCancel}
        destroyOnClose
        centered
        okText={t("students.send")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="sendNotficationForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="title"
              label={t("students.address")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="msg"
              label={t("students.msg")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title={t("students.info")}
        visible={infoModalVisible}
        footer={""}
        destroyOnClose
        onCancel={handleInfoCancel}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label={t("students.image")}>
            <Avatar size="large" src={user.image}></Avatar>
          </Descriptions.Item>
          <Descriptions.Item label={t("students.userName")}>
            {user.username}
          </Descriptions.Item>
          <Descriptions.Item label={t("students.gender")}>
            {user.gender}
          </Descriptions.Item>
          <Descriptions.Item label={t("students.country")}>
            {user.country_id && user.country_id.flag}
          </Descriptions.Item>
          <Descriptions.Item label={t("students.eamil")}>
            {user.email}
          </Descriptions.Item>
          <Descriptions.Item label={t("students.phone")}>
            {user.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label={t("students.min")}>
            {user.minutes}
          </Descriptions.Item>
          <Descriptions.Item label={t("students.wallet")}>
            {user.wallet}
          </Descriptions.Item>
          {user.subscribe && user.subscribe[user.subscribe.length - 1] && (
            <>
              <Descriptions.Item label={t("students.startSub")}>
                {user.subscribe[user.subscribe.length - 1].from_date &&
                  moment(
                    user.subscribe[user.subscribe.length - 1].from_date
                  ).format("YYYY-MM-DD")}
              </Descriptions.Item>
              <Descriptions.Item label={t("students.endSub")}>
                {user.subscribe[user.subscribe.length - 1].to_date &&
                  moment(
                    user.subscribe[user.subscribe.length - 1].to_date
                  ).format("YYYY-MM-DD")}
              </Descriptions.Item>
              <Descriptions.Item label={t("students.couponCode")}>
                {user.subscribe[user.subscribe.length - 1].couponCode}
              </Descriptions.Item>
              <Descriptions.Item label={t("students.discount")}>
                {user.subscribe[user.subscribe.length - 1].discount}
              </Descriptions.Item>
              <Descriptions.Item label={t("students.total")}>
                {user.subscribe[user.subscribe.length - 1].total}
              </Descriptions.Item>
              <Descriptions.Item label={t("students.packageName")}>
                {user.subscribe[user.subscribe.length - 1].package_id.arName}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      </Modal>
    </>
  );
}
export default OurTeam;
