import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Spin,
  Space,
  message,
  Tooltip,
} from "antd";
import { EditFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { list, edit } from "../../Api/StaticPages";
import MyCKEditor from "../../Utils/MyCKEditor";
import { useTranslation } from "react-i18next";

function OurTeam() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("staticPages.type"),
      dataIndex: "Type",
    },
    {
      title: t("staticPages.arTitle"),
      dataIndex: "arTitle",
    },

    {
      title: t("staticPages.enTitle"),
      dataIndex: "enTitle",
    },
    {
      title: t("staticPages.operations"),
      key: "actions",
      width: "fit-content",
      align: "center",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("staticPages.edit")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(record)}
            >
              <EditFilled />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await list();
      console.log(res.data.items);
      setData(res.data.items);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handleAddOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      const data = form.getFieldsValue();
      await edit(
        { ...data, arContent: content1, enContent: content2 },
        adminId
      );
      setAddModalVisible(false);
      message.success(t("staticPages.editPageDone"));
      await updateTable();
      setModalLoading(false);
      setContent1("");
      setContent2("");
    } catch (error) {
      message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleAddCancel = () => {
    setAddModalVisible(false);
    setAdminId(null);
    setContent1("");
    setContent2("");
  };

  const handleEdit = async ({
    _id,
    arContent,
    enContent,
    arTitle,
    enTitle,
    Type,
  }) => {
    setAdminId(_id);
    setContent1(arContent);
    setContent2(enContent);
    form.setFieldsValue({
      arContent,
      enContent,
      arTitle,
      enTitle,
      Type,
    });
    setAddModalVisible(true);
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, []);

  return (
    <>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="student_name"
        pagination={{ showSizeChanger: false }}
      />

      <Modal
        title={t("staticPages.editPage")}
        visible={addModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        destroyOnClose
        centered
        okText={t("staticPages.edit")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="Type"
              label={t("staticPages.pageType")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="arTitle"
              label={t("staticPages.arTitle")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="arContent"
              label={t("staticPages.arContent")}
              rules={[{ required: true }]}
            >
              <Input hidden />
              <MyCKEditor content={content1} contentSetter={setContent1} />
            </Form.Item>
            <Form.Item
              name="enTitle"
              label={t("staticPages.enTitle")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="enContent"
              label={t("staticPages.enContent")}
              rules={[{ required: true }]}
            >
              <Input hidden />
              <MyCKEditor content={content2} contentSetter={setContent2} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default OurTeam;
