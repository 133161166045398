import instance from './index';

export const list = (page, pageSize, data) => {
  return instance.post(
    `user/list?page=${page - 1}&limit=${pageSize}`,
    //{ search_field: "email", search_value: "" },
    data,
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};

export const userData = (id) => {
  return instance.get('user/get/' + id, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const blockUser = (id, isBlock) => {
  return instance.post(
    `user/block/${id}`,
    { isBlock: !isBlock },
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};

export const sendNotification = (id, data) => {
  return instance.post(`user/notification/${id}`, data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
