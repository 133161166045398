import { notification } from "antd";
import { login, isAuth, logout } from "../Api/Auth";

const Authenticate = {
  isAuthenticated: false,
  authenticate(data, cb, err, t) {
    login(data)
      .then((res) => {
        Authenticate.isAuthenticated = true;
        localStorage.setItem("token", res.data.items.token);
        cb(res.data.items);
      })
      .catch((error) => {
        if (error.response)
          notification.error({
            message: t("errors.login"),
            description: error.response.data.message,
          });
        err(error);
      });
  },
  checkAuth(cb) {
    isAuth()
      .then((res) => {
        Authenticate.isAuthenticated = true;
        cb(res.data.items);
      })
      .catch((err) => {
        Authenticate.isAuthenticated = false;
        cb({});
      });
  },
  signout(cb, id) {
    logout(id)
      .then(() => {
        Authenticate.isAuthenticated = false;
        setTimeout(cb, 100);
      })
      .catch(() => {
        Authenticate.isAuthenticated = false;
        setTimeout(cb, 100);
      });
  },
};
export default Authenticate;
