import instance from "./index";

export const list = () => {
  return instance.get("constant/subject-type", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const add = (data) => {
  return instance.post("constant/subject-type", data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const edit = (data, id) => {
  return instance.post("constant/subject-type/" + id, data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const deleteSubjectType = (id) => {
  return instance.post(
    `constant/subject-type_delete/${id}`,
    {},
    {
      headers: {
        token: localStorage.getItem("token"),
      },
    }
  );
};
