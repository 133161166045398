import {
  Table,
  Form,
  Button,
  Row,
  message,
  Pagination,
  Tag,
  Col,
  Select,
} from "antd";
import { ClearOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { list, userAll } from "../../Api/Payment";
import { list as listSubscription } from "../../Api/Subscription";
const { Option } = Select;

function Payment() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("payment.packageId"),
      dataIndex: "package_id",
      render: (_) => _ && _["arName"],
    },
    {
      title: t("payment.userId"),
      dataIndex: "user_id",
      render: (_) => _ && _["username"],
    },
    {
      title: t("payment.fromDate"),
      dataIndex: "from_date",
      render: (_) => moment(_).format("YYYY-MM-DD"),
    },
    {
      title: t("payment.toDate"),
      dataIndex: "to_date",
      render: (_) => moment(_).format("YYYY-MM-DD"),
    },
    {
      title: t("payment.transactionId"),
      dataIndex: "transaction_id",
    },
    {
      title: t("payment.discount"),
      dataIndex: "discount",
    },
    {
      title: t("payment.amount"),
      dataIndex: "amount",
    },
    {
      title: t("payment.total"),
      dataIndex: "total",
    },
    {
      title: t("payment.isFinish"),
      dataIndex: "isFinish",
      width: 100,
      render: (_, record) => (
        <Tag color={record.isFinish ? "red" : "green"}>
          {record.isFinish ? t("payment.end") : t("payment.active")}
        </Tag>
      ),
    },
  ];

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [subscription, setSubscription] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [initData] = useState({
    user_id: "",
    package_id: "",
    isFinish: "",
  });

  const onChangePage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const updateTable = async (data) => {
    try {
      setLoading(true);
      if (!data) data = initData;
      const res = await list(page, pageSize, data);
      setData(res.data.items);
      setTotalPage(res.data.pagenation.totalElements);
      setLoading(false);
    } catch (error) {
      console.log(error);
      console.log({ ...error });
      message.error(t("errors.server"));
    }
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
        const users = await userAll();
        const subscriptions = await listSubscription();
        setUser(users.data.items);
        setSubscription(subscriptions.data.items);
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, [pageSize, page]);

  const handleSearch = () => {
    const data = form.getFieldsValue();
    if (!data.user_id) data.user_id = "";
    if (!data.package_id) data.package_id = "";
    if (!data.isFinish) data.isFinish = "";
    updateTable(data);
  };

  return (
    <>
      <Form form={form}>
        <Row>
          <Col span={5} offset={1}>
            <Form.Item name="user_id" label={t("payment.user")}>
              <Select>
                {user.map((e) => (
                  <Option key={e._id} value={e._id}>
                    {e.username}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item name="package_id" label={t("payment.packageId")}>
              <Select>
                {subscription.map((e) => (
                  <Option key={e._id} value={e._id}>
                    {e.arName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item name="isFinish" label={t("payment.isFinish")}>
              <Select>
                <Option key={1} value={false}>
                  {t("payment.active")}
                </Option>
                <Option key={2} value={true}>
                  {t("payment.notActive")}
                </Option>
                <Option key={3} value={""}>
                  {t("payment.both")}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={handleSearch}>
                {t("payment.search")}
              </Button>
              <Button
                style={{ marginInlineStart: "2px" }}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  form.setFieldsValue({
                    user_id: "",
                    package_id: "",
                    isFinish: "",
                  });
                  updateTable();
                }}
              >
                <ClearOutlined />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={false}
      />
      <br />
      <Pagination
        current={page}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onChangePage}
        total={totalPage}
      />
    </>
  );
}
export default Payment;
