import instance from "./index";

export const oldNotification = (page, pageSize) => {
  return instance.get(`notification/list?page=${page - 1}&limit=${pageSize}`, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const adminNotification = (page, pageSize) => {
  return instance.get(
    `/notification/list_admin?page=${page - 1}&limit=${pageSize}`,
    {
      headers: {
        token: localStorage.getItem("token"),
        "Accept-Language": localStorage.getItem("i18nextLng"),
      },
    }
  );
};

export const addNotification = (data) => {
  return instance.post("/notification/add", data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
