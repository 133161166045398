import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Spin,
  Space,
  message,
  Popconfirm,
  Tooltip,
  Radio,
  InputNumber,
} from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { list, add, edit, deleteSub } from "../../Api/Subscription";
import { useTranslation } from "react-i18next";

function Subscription() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("subscription.arName"),
      dataIndex: "arName",
    },
    {
      title: t("subscription.enName"),
      dataIndex: "enName",
    },
    {
      title: t("subscription.price"),
      dataIndex: "price",
    },
    {
      title: t("subscription.months"),
      dataIndex: "months",
    },
    {
      title: t("subscription.days"),
      dataIndex: "days",
    },
    {
      title: t("subscription.minutes"),
      dataIndex: "minutes",
    },
    {
      title: t("subscription.operations"),
      key: "actions",
      width: "fit-content",
      align: "center",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("subscription.editSubscription")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(record)}
            >
              <EditFilled />
            </Button>
          </Tooltip>
          <Popconfirm
            title={t("subscription.sureDelete")}
            onConfirm={() => handleDelete(record._id)}
          >
            <Tooltip title={t("subscription.deleteSubscription")}>
              <Button danger size="small">
                <DeleteFilled />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [subType, setSubType] = useState(null);

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await list();
      setData(res.data.items);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handleAddOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      const data = form.getFieldsValue();
      if (subType === "monthly") data.days = 0;
      else data.months = 0;
      if (editId) await edit(data, editId);
      else await add(data);
      setAddModalVisible(false);
      message.success(
        editId
          ? t("subscription.editSubscriptionDone")
          : t("subscription.addSubscriptionDone")
      );
      setSubType(null);
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      if (error.response) message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleAddCancel = () => {
    setAddModalVisible(false);
    setEditId(null);
    setSubType(null);
  };

  const handleAdd = () => {
    setEditId(null);
    setAddModalVisible(true);
  };

  const handleEdit = async ({
    _id,
    arName,
    enName,
    price,
    months,
    days,
    minutes,
  }) => {
    if (days) setSubType("daily");
    else setSubType("monthly");
    setEditId(_id);
    form.setFieldsValue({
      arName,
      enName,
      price,
      months,
      days,
      minutes,
      "sub-type": days ? "daily" : "monthly",
    });
    setAddModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteSub(id);
      updateTable();
      message.success(t("subscription.deleteSubscriptionDone"));
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, []);

  return (
    <>
      <Row justify="end">
        <Button
          loading={loading}
          className="ant-btn-big"
          onClick={handleAdd}
          type="primary"
        >
          {t("subscription.addSubscription")}
        </Button>
      </Row>
      <br />
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={{ showSizeChanger: false }}
      />

      <Modal
        title={
          editId
            ? t("subscription.editSubscription")
            : t("subscription.addSubscription")
        }
        visible={addModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        destroyOnClose
        centered
        okText={editId ? t("subscription.edit") : t("subscription.add")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="arName"
              label={t("subscription.arName")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="enName"
              label={t("subscription.enName")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="price"
              label={t("subscription.price")}
              rules={[{ required: true, type: "integer" }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="sub-type"
              label={t("subscription.subType")}
              rules={[{ required: true }]}
            >
              <Radio.Group onChange={(e) => setSubType(e.target.value)}>
                <Radio value="daily">{t("subscription.daily")}</Radio>
                <Radio value="monthly">{t("subscription.monthly")}</Radio>
              </Radio.Group>
            </Form.Item>
            {subType === "monthly" ? (
              <Form.Item
                name="months"
                label={t("subscription.months")}
                rules={[{ required: true, type: "integer" }]}
              >
                <InputNumber />
              </Form.Item>
            ) : subType === "daily" ? (
              <Form.Item
                name="days"
                label={t("subscription.days")}
                rules={[{ required: true, type: "integer" }]}
              >
                <InputNumber />
              </Form.Item>
            ) : null}
            <Form.Item
              name="minutes"
              label={t("subscription.minutes")}
              rules={[{ required: true, type: "integer" }]}
            >
              <InputNumber />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default Subscription;
