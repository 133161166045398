import instance from "./index";

export const list = (page, pageSize, data) => {
  return instance.post(
    `conversation/list?page=${page - 1}&limit=${pageSize}`,
    data,
    {
      headers: {
        token: localStorage.getItem("token"),
      },
    }
  );
};
