import instance from './index';

const counters = () => {
  return instance.get('report/counters', {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
const userByGender = () => {
  return instance.get('report/users_gender', {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
const userByAges = () => {
  return instance.get('report/users_ages', {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
const userByCountries = () => {
  return instance.get('report/users_countries', {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
const userByConversationType = () => {
  return instance.get('report/conversation_type', {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
const userByNewUsers = () => {
  return instance.get('report/new_users', {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
const userBySubscribeToFinish = () => {
  return instance.get('report/subscribe_to_finish', {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
const Home = {
  counters,
  userByAges,
  userByGender,
  userByNewUsers,
  userByCountries,
  userByConversationType,
  userBySubscribeToFinish,
};
export default Home;
