import { Table, message } from "antd";
import { useEffect, useState } from "react";
import { allCountries } from "../../Api/Country";
import { useTranslation } from "react-i18next";

function Countries() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("countries.name"),
      dataIndex: "name",
    },
    {
      title: t("countries.flag"),
      dataIndex: "flag",
    },
    {
      title: t("countries.code"),
      dataIndex: "code",
    },
  ];
  const [loading, setLoading] = useState(true);
  const [countries, setAllCountries] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await allCountries();
        setAllCountries(res.data.items);
        setLoading(false);
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    getData();
  }, []);

  return (
    <>
      <Table
        loading={loading}
        dataSource={countries}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={{ showSizeChanger: false }}
      />
    </>
  );
}
export default Countries;
