import instance from "./index";

export const list = () => {
  return instance.get("constant/settings", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const edit = (data, id) => {
  return instance.post("constant/settings/" + id, data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
