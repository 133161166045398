import { useEffect, useState } from "react";
import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Spin,
  Space,
  message,
  Popconfirm,
  Tooltip,
  Select,
} from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { allCities, deleteCity, add, edit } from "../../Api/City";
import { allCountries } from "../../Api/Country";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function Cities() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("cities.arName"),
      dataIndex: "arName",
    },
    {
      title: t("cities.enName"),
      dataIndex: "enName",
    },
    {
      title: t("cities.countryName"),
      dataIndex: "country_id",
      render: (_) => _["arName"],
    },
    {
      title: t("cities.countryFlag"),
      dataIndex: "country_id",
      render: (_) => _["flag"],
    },
    {
      title: t("cities.operations"),
      y: "actions",
      width: "fit-content",
      align: "center",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("cities.editCities")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(record)}
            >
              <EditFilled />
            </Button>
          </Tooltip>
          <Popconfirm
            title={t("cities.editCities")}
            onConfirm={() => handleDelete(record._id)}
          >
            <Tooltip title={t("cities.deleteCities")}>
              <Button danger size="small">
                <DeleteFilled />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const [cities, setAllCities] = useState([]);
  const [countries, setAllCountries] = useState([]);

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await allCities();
      const resCountries = await allCountries();
      setAllCities(res.data.items);
      setAllCountries(resCountries.data.items);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handleAddCancel = () => {
    setAddModalVisible(false);
    setAdminId(null);
  };

  const handleAddOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      const data = form.getFieldsValue();
      if (adminId) await edit(data, adminId);
      else await add(data);
      setAddModalVisible(false);
      message.success(
        adminId ? t("cities.editCitiesDone") : t("cities.addCitiesDone")
      );
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleAdd = () => {
    setAdminId(null);
    setAddModalVisible(true);
  };

  const handleEdit = async ({
    _id,
    enName,
    arName,
    country_id: { _id: countryId },
  }) => {
    setAdminId(_id);
    form.setFieldsValue({
      enName,
      arName,
      country_id: countryId,
    });
    setAddModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteCity(id);
      updateTable();
      message.success(t("cities.deleteCitiesDone"));
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, []);

  return (
    <>
      <Row justify="end">
        <Button
          loading={loading}
          className="ant-btn-big"
          onClick={handleAdd}
          type="primary"
        >
          {t("cities.addCities")}
        </Button>
      </Row>
      <br />
      <Table
        loading={loading}
        dataSource={cities}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={{ showSizeChanger: false }}
      />

      <Modal
        title={adminId ? t("cities.editCities") : t("cities.addCities")}
        visible={addModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        destroyOnClose
        centered
        okText={adminId ? t("cities.edit") : t("cities.add")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="arName"
              label={t("cities.arName")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="enName"
              label={t("cities.enName")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="country_id"
              label={t("cities.countryName")}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {countries.map((ele) => {
                  return (
                    <Option key={ele["_id"]} value={ele["_id"]}>
                      {ele["name"]}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default Cities;
