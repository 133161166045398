import instance from './index';

export const list = (page, pageSize, data) => {
  return instance.post(
    `teacher/list?page=${page - 1}&limit=${pageSize}`,
    data,
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};
export const allTracher = () => {
  return instance.get('teacher/all', {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const add = (data) => {
  return instance.post('teacher/add', data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
export const edit = (data, id) => {
  return instance.post('teacher/update/' + id, data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const deleteTeacher = (id) => {
  return instance.post(
    `teacher/delete/${id}`,
    { isBlock: true },
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};

export const verifyTeacher = (id) => {
  return instance.post(
    `teacher/activate/${id}`,
    { isVerify: true },
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};

export const blockTeacher = (id, isBlock) => {
  return instance.post(
    `teacher/block/${id}`,
    { isBlock: !isBlock },
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};

export const sendNotification = (id, data) => {
  return instance.post(`teacher/notification/${id}`, data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const editImage = (id, data) => {
  return instance.post(`teacher/update-image/${id}`, data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const editVideo = (id, data) => {
  return instance.post(`teacher/update-video/${id}`, data, {
    headers: {
      token: localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data',
    },
  });
};
