import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Spin,
  Space,
  message,
  Popconfirm,
  Tooltip,
  Upload,
  Avatar,
  Tag,
} from "antd";
import ImgCrop from "antd-img-crop";
import {
  EditFilled,
  DeleteFilled,
  CheckSquareFilled,
  StopOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  listAdv,
  add,
  deletePage,
  adv,
  edit,
  activeOrDeActive,
} from "../../Api/AdvPage";
import { useTranslation } from "react-i18next";

function Adv() {
  const { t } = useTranslation();
  const columns = [
    {
      title: "صور الاعلان",
      dataIndex: "image",
      render: (_) => <Avatar src={_} />,
    },
    {
      title: t("adv.enName"),
      dataIndex: "enName",
    },
    {
      title: t("adv.arName"),
      dataIndex: "arName",
    },
    {
      title: t("adv.enDescription"),
      dataIndex: "enDescription",
    },
    {
      title: t("adv.arDescription"),
      dataIndex: "arDescription",
    },
    {
      title: t("adv.isActive"),
      dataIndex: "isActive",
      render: (_, record) => (
        <Tag color={record.isActive ? "green" : "red"}>
          {record.isActive ? t("adv.active") : t("adv.notActive")}
        </Tag>
      ),
    },
    {
      title: t("adv.operations"),
      key: "actions",
      width: "fit-content",
      align: "center",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("adv.editAdvType")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(record)}
            >
              <EditFilled />
            </Button>
          </Tooltip>
          <Popconfirm
            title={t("adv.sureDelete")}
            onConfirm={() => handleDelete(record._id)}
          >
            <Tooltip title={t("adv.deleteAdvType")}>
              <Button danger size="small">
                <DeleteFilled />
              </Button>
            </Tooltip>
          </Popconfirm>
          {!record.isActive ? (
            <Tooltip title={t("adv.activeAdv")}>
              <Button
                type="primary"
                ghost
                size="small"
                onClick={() =>
                  handelActiveOrDeActive(record._id, record.isActive)
                }
              >
                <CheckSquareFilled />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("adv.stopAdv")}>
              <Button
                size="small"
                danger
                onClick={() =>
                  handelActiveOrDeActive(record._id, record.isActive)
                }
              >
                <StopOutlined />
              </Button>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [welcomePageId, setWelcomePageId] = useState(null);

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await listAdv();
      setData(res.data.items);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handelActiveOrDeActive = async (id, isActive) => {
    try {
      await activeOrDeActive(id, isActive);
      updateTable();
      message.success(
        !isActive ? t("adv.activeAdvDone") : t("adv.stopAdvDone")
      );
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handleAddOk = async () => {
    form.setFieldsValue({ image: fileList.length === 0 ? "" : "True" });
    try {
      await form.validateFields();
      setModalLoading(true);
      const formData = new FormData();
      const objData = form.getFieldsValue();
      for (let key in objData) {
        formData.append(key, objData[key]);
      }
      if (fileList[0].uid !== -1) formData.set("image", fileList[0]);
      else formData.delete("image");
      if (welcomePageId) await edit(formData, welcomePageId);
      else await add(formData);
      setAddModalVisible(false);
      message.success(
        welcomePageId ? t("adv.editAdvDone") : t("adv.addAdvDone")
      );
      await updateTable();
      setModalLoading(false);
      setFileList([]);
    } catch (error) {
      if (error.response) message.error(t("errors.server"));
      setModalLoading(false);
    }
  };
  const handleAddCancel = () => {
    setAddModalVisible(false);
    setWelcomePageId(null);
    setFileList([]);
  };
  const handleAdd = () => {
    setWelcomePageId(null);
    setAddModalVisible(true);
  };
  const handleEdit = async (a) => {
    try {
      const {
        data: { items },
      } = await adv(a._id);
      console.log(items);
      form.setFieldsValue({
        enName: items.enName,
        arName: items.arName,
        arDescription: items.arDescription,
        enDescription: items.enDescription,
        image: items.image,
      });
      setFileList([{ uid: -1, url: items.image }]);
    } catch (error) {
      message.error(t("errors.server"));
    }
    setWelcomePageId(a._id);
    setAddModalVisible(true);
  };
  const handleDelete = async (id) => {
    try {
      await deletePage(id);
      updateTable();
      message.success(t("adv.deleteAdvDone"));
    } catch (error) {
      message.error(t("errors.server"));
    }
  };
  const onChange = ({ fileList: newFileList }) => {
    !newFileList.length && form.setFieldsValue({ image: "" });
    setFileList(newFileList);
  };
  const beforeUpload = (file) => {
    setFileList([...fileList, file]);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    form.setFieldsValue({ image: "True" });
    reader.onloadend = () => {
      file.url = reader.result;
      setFileList([file]);
    };
    return false;
  };
  useEffect(() => updateTable(), []);

  return (
    <>
      <Row justify="end">
        <Button
          loading={loading}
          className="ant-btn-big"
          onClick={handleAdd}
          type="primary"
        >
          {t("adv.addAdv")}
        </Button>
      </Row>
      <br />
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
      />

      <Modal
        title={welcomePageId ? t("adv.editAdv") : t("adv.addAdv")}
        visible={addModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        destroyOnClose
        centered
        okText={welcomePageId ? t("adv.edit") : t("adv.add")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="enName"
              label={t("adv.enName")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="arName"
              label={t("adv.arName")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="enDescription"
              label={t("adv.enDescription")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="arDescription"
              label={t("adv.arDescription")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="image" label={t("adv.image")} rules={[{ required: true }]}>
              <Input hidden />
              <ImgCrop rotate>
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  beforeUpload={beforeUpload}
                  showUploadList={{ showPreviewIcon: false }}
                >
                  {fileList.length < 1 && "+ Upload"}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default Adv;
