import instance from './index';

export const allCities = () => {
  return instance.get('constant/city', {
    headers: {
      token: localStorage.getItem('token'),
      'Accept-Language': localStorage.getItem('i18nextLng'),
    },
  });
};

export const allCityByCountry = (id) => {
  console.log({ id });
  return instance.get(`constant/city_by_country/${id}`, {
    headers: {
      token: localStorage.getItem('token'),
      'Accept-Language': localStorage.getItem('i18nextLng'),
    },
  });
};

export const deleteCity = (id) => {
  return instance.post(
    `constant/city_delete/${id}`,
    {},
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};

export const add = (data) => {
  return instance.post('constant/city', data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const edit = (data, id) => {
  return instance.post("constant/city/" + id, data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};