import { useContext } from "react";
import {
  FileTextFilled,
  UserOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined,
  TableOutlined,
  TeamOutlined,
  NotificationOutlined,
  BorderlessTableOutlined,
  ReadOutlined,
  ProfileOutlined,
  RiseOutlined,
  TagsOutlined,
  CloseCircleOutlined,
  UnorderedListOutlined,
  MessageOutlined,
  ExceptionOutlined,
  BellOutlined,
  FlagOutlined,
  OneToOneOutlined,
  DollarOutlined,
  AimOutlined,
  FireOutlined,
  SettingFilled,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "./../../context";

const { SubMenu } = Menu;
const { Sider } = Layout;

function SideMenu() {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const location = useLocation();
  const currentUser = { permissions: user && user.roles };
  return (
    <Sider style={{ overflow: "auto" }} collapsible breakpoint="lg" width={300}>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{ borderRight: 0, minHeight: "100%" }}
      >
        {currentUser.permissions & (1 << 0) && (
          <Menu.Item key="/" icon={<FileTextFilled />}>
            <Link to="/">{t("nav.home")}</Link>
          </Menu.Item>
        )}
        {currentUser.permissions & ((1 << 1) | (1 << 2) | (1 << 3)) && (
          <SubMenu key="users" icon={<TeamOutlined />} title={t("nav.users")}>
            {currentUser.permissions & (1 << 1) && (
              <Menu.Item key="/admin" icon={<UserOutlined />}>
                <Link to="/admin">{t("nav.admins")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 2) && (
              <Menu.Item key="/teacher" icon={<UserOutlined />}>
                <Link to="/teacher">{t("nav.teachers")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 3) && (
              <Menu.Item key="/users" icon={<UserOutlined />}>
                <Link to="/users">{t("nav.students")}</Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}
        {currentUser.permissions &
          ((1 << 4) |
            (1 << 5) |
            (1 << 6) |
            (1 << 7) |
            (1 << 8) |
            (1 << 9) |
            (1 << 10) |
            (1 << 11) |
            (1 << 12) |
            (1 << 13) |
            (1 << 14)) && (
          <SubMenu
            key="constants"
            icon={<TableOutlined />}
            title={t("nav.constants")}
          >
            {currentUser.permissions & (1 << 4) && (
              <Menu.Item key="/welcome-page" icon={<PlayCircleOutlined />}>
                <Link to="/welcome-page">{t("nav.welcomePages")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 5) && (
              <Menu.Item key="/static-pages" icon={<ReadOutlined />}>
                <Link to="/static-pages">{t("nav.staticPages")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 6) && (
              <Menu.Item key="/help-page" icon={<QuestionCircleOutlined />}>
                <Link to="/help-page">{t("nav.helpPage")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 7) && (
              <Menu.Item key="/homePage" icon={<ProfileOutlined />}>
                <Link to="/homePage">{t("nav.editTheMain")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 8) && (
              <Menu.Item key="/level" icon={<RiseOutlined />}>
                <Link to="/level">{t("nav.studentLevels")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 9) && (
              <Menu.Item key="/subscription" icon={<TagsOutlined />}>
                <Link to="/subscription">{t("nav.subscriptions")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 10) && (
              <Menu.Item key="/subject-type" icon={<OneToOneOutlined />}>
                <Link to="/subject-type">{t("nav.typesOfTest")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 11) && (
              <Menu.Item key="/complains-type" icon={<CloseCircleOutlined />}>
                <Link to="/complains-type">{t("nav.typesOfComplaints")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 12) && (
              <Menu.Item key="/language" icon={<UnorderedListOutlined />}>
                <Link to="/language">{t("nav.languages")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 13) && (
              <Menu.Item key="/country" icon={<FlagOutlined />}>
                <Link to="/country">{t("nav.countries")}</Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 14) && (
              <Menu.Item key="/city" icon={<AimOutlined />}>
                <Link to="/city">{t("nav.theCities")}</Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}
        {currentUser.permissions & ((1 << 15) | (1 << 16)) && (
          <SubMenu
            key="notification"
            icon={<NotificationOutlined />}
            title={t("nav.notifications")}
          >
            {currentUser.permissions & (1 << 15) && (
              <Menu.Item key="/old-notification" icon={<BellOutlined />}>
                <Link to="/old-notification">
                  {t("nav.notificationsJudgments")}
                </Link>
              </Menu.Item>
            )}
            {currentUser.permissions & (1 << 16) && (
              <Menu.Item key="/admin-notification" icon={<BellOutlined />}>
                <Link to="/admin-notification">
                  {t("nav.notificationsAdmin")}
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {currentUser.permissions & (1 << 17) && (
          <Menu.Item key="/rate" icon={<ExceptionOutlined />}>
            <Link to="/rate">{t("nav.ratings")}</Link>
          </Menu.Item>
        )}
        {currentUser.permissions & (1 << 18) && (
          <Menu.Item key="/complains" icon={<CloseCircleOutlined />}>
            <Link to="/complains">{t("nav.complaints")}</Link>
          </Menu.Item>
        )}
        {currentUser.permissions & (1 << 19) && (
          <Menu.Item key="/adv" icon={<FireOutlined />}>
            <Link to="/adv">{t("nav.ads")}</Link>
          </Menu.Item>
        )}
        {currentUser.permissions & (1 << 20) && (
          <Menu.Item key="/payment" icon={<DollarOutlined />}>
            <Link to="/payment">{t("nav.financialAccounts")}</Link>
          </Menu.Item>
        )}
        {currentUser.permissions & (1 << 21) && (
          <Menu.Item key="/conversation" icon={<MessageOutlined />}>
            <Link to="/conversation">{t("nav.chats")}</Link>
          </Menu.Item>
        )}
        {currentUser.permissions & (1 << 22) && (
          <Menu.Item key="/coupons" icon={<BorderlessTableOutlined />}>
            <Link to="/coupons">{t("nav.capones")}</Link>
          </Menu.Item>
        )}
        {currentUser.permissions & (1 << 23) && (
          <Menu.Item key="/settings" icon={<SettingFilled />}>
            <Link to="/settings">{t("nav.settings")}</Link>
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
}

export default SideMenu;
