import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Spin,
  message,
  Radio,
  Pagination,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { oldNotification, addNotification } from "../../Api/Notification";
import { useTranslation } from "react-i18next";

function OldNotification() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("oldNotification.fromName"),
      dataIndex: "fromName",
    },
    {
      title: t("oldNotification.toName"),
      dataIndex: "toName",
    },
    {
      title: t("oldNotification.title"),
      dataIndex: "title",
    },
    {
      title: t("oldNotification.msg"),
      dataIndex: "msg",
    },
    {
      title: t("oldNotification.dtDate"),
      dataIndex: "dt_date",
      render: (_) => moment(_).format("YYYY-MM-DD"),
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [notficationModalVisible, setNotficationModalVisible] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const onChangePage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await oldNotification(page, pageSize);
      setData(res.data.items);
      setTotalPage(res.data.pagination.totalElements);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handleNotficationOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      const data = form.getFieldsValue();

      await addNotification(data);
      setNotficationModalVisible(false);
      message.success(t("oldNotification.sentDone"));
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleNotficationCancel = () => {
    setNotficationModalVisible(false);
    // setTeacherId(null);
  };

  const handleOpenNotficationModel = () => {
    setNotficationModalVisible(true);
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, [page, pageSize]);

  return (
    <>
      <Row justify="end">
        <Button
          loading={loading}
          className="ant-btn-big"
          onClick={handleOpenNotficationModel}
          type="primary"
        >
          {t("oldNotification.sent")}
        </Button>
      </Row>
      <br />
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={false}
      />
      <br />
      <Pagination
        current={page}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onChangePage}
        total={totalPage}
      />

      <Modal
        title={t("oldNotification.sent")}
        visible={notficationModalVisible}
        onOk={handleNotficationOk}
        onCancel={handleNotficationCancel}
        destroyOnClose
        centered
        okText={t("oldNotification.send")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="sendNotficationForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="titel"
              label={t("oldNotification.titel")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="msg"
              label={t("oldNotification.msg")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="user_type"
              label={t("oldNotification.userType")}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio.Button value="1">
                  {t("oldNotification.student")}
                </Radio.Button>
                <Radio.Button value="2">
                  {t("oldNotification.teacher")}
                </Radio.Button>
                <Radio.Button value="3">
                  {t("oldNotification.all")}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default OldNotification;
