import {
  Table,
  Form,
  Button,
  Row,
  message,
  Pagination,
  Tag,
  Col,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { list } from "../../Api/Conversation";
import { userAll } from "../../Api/Payment";
import { allTracher } from "../../Api/Teacher";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function Conversation() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("conversation.userId"),
      dataIndex: "user_id",
      render: (_) => _ && _["username"],
    },
    {
      title: t("conversation.teacherId"),
      dataIndex: "teacher_id",
      render: (_) => _ && _["first_name"],
    },
    {
      title: t("conversation.minutes"),
      dataIndex: "minutes",
    },
    {
      title: t("conversation.actualMinutes"),
      dataIndex: "actual_minutes",
    },
    {
      title: t("conversation.isFinish"),
      dataIndex: "isFinish",
      width: 100,
      render: (_, record) => (
        <Tag color={record.isFinish ? "red" : "green"}>
          {record.isFinish
            ? t("conversation.notActive")
            : t("conversation.active")}
        </Tag>
      ),
    },
    {
      title: t("conversation.isExam"),
      dataIndex: "isExam",
      width: 100,
      render: (_, record) => (
        <Tag color={record.isExam ? "green" : "red"}>
          {record.isExam ? t("conversation.yse") : t("conversation.no")}
        </Tag>
      ),
    },
  ];

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [user, setUser] = useState([]);
  const [tracher, setTracher] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [initData] = useState({
    user_id: "",
    teacher_id: "",
    isFinish: "",
    isExam: "",
  });

  const onChangePage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const updateTable = async (data) => {
    try {
      setLoading(true);
      if (!data) data = initData;
      const res = await list(page, pageSize, data);
      setData(res.data.items);
      setTotalPage(res.data.pagenation.totalElements);
      setLoading(false);
    } catch (error) {
      console.log(error);
      console.log({ ...error });
      message.error(t("errors.server"));
    }
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
        const users = await userAll();
        const tracher = await allTracher();
        setUser(users.data.items);
        setTracher(tracher.data.items);
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, [page, pageSize]);

  const handleSearch = () => {
    const data = form.getFieldsValue();
    if (!data.user_id) data.user_id = "";
    if (!data.teacher_id) data.teacher_id = "";
    if (!data.isFinish) data.isFinish = "";
    if (!data.isExam) data.isExam = "";
    updateTable(data);
  };

  return (
    <>
      <Form form={form}>
        <Row justify="center">
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="user_id" label={t("conversation.user")}>
              <Select>
                {user.map((e) => (
                  <Option key={e._id} value={e._id}>
                    {e.username}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="teacher_id"
              label={t("conversation.teacherNumber")}
            >
              <Select>
                {tracher.map((e) => (
                  <Option key={e._id} value={e._id}>
                    {e.first_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="isFinish" label={t("conversation.status")}>
              <Select>
                <Option value={false}>{t("conversation.active")}</Option>
                <Option value={true}>{t("conversation.notactive")}</Option>
                <Option value={""}>{t("conversation.both")}</Option>
              </Select>
            </Form.Item>
            <Form.Item name="isExam" label={t("conversation.isTest")}>
              <Select>
                <Option value={false}>{t("conversation.no")}</Option>
                <Option value={true}>{t("conversation.yse")}</Option>
                <Option value={""}>{t("conversation.both")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={handleSearch}>
                {t("conversation.search")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={false}
      />
      <br />
      <Pagination
        current={page}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onChangePage}
        total={totalPage}
      />
    </>
  );
}
export default Conversation;
