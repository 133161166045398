import instance from "./index";

export const list = () => {
  return instance.get("constant/languages", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const add = (data) => {
  return instance.post("constant/languages", data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const edit = (data, id) => {
  return instance.post("constant/languages/" + id, data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const deleteLanguages = (id) => {
  return instance.post(
    `constant/languages_delete/${id}`,
    {},
    {
      headers: {
        token: localStorage.getItem("token"),
      },
    }
  );
};
