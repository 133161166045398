import instance from './index';

export const list = () => {
  return instance.get(`coupon/list?page=0&limit=1000`, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const couponDetails = (id) => {
  return instance.get('coupon/get/' + id, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const couponUse = (id) => {
  return instance.post(
    'coupon/usage?page=0&limit=10',
    { couponCode: id },
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};

export const add = (data) => {
  return instance.post('coupon/add', data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
export const edit = (data, id) => {
  return instance.post('coupon/update/' + id, data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const deleteCoupon = (id) => {
  return instance.post(`coupon/delete/${id}`, null, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
