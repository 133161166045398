import instance from './index';

export const list = (page, pageSize, data) => {
  return instance.get(
    `conversation/rate?page=${page - 1}&limit=${pageSize}&teacher_id=${data}`,
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};
