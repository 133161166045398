import instance from './index';

export const list = (page, pageSize, data) => {
  return instance.post(
    `payment/list?page=${page - 1}&limit=${pageSize}`,
    data,
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};

export const userAll = () => {
  return instance.get(`user/all`, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
