import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Spin,
  Space,
  message,
  Popconfirm,
  Select,
  Tag,
  Tooltip,
  Upload,
  Descriptions,
  Avatar,
  Pagination,
  Col,
  Radio,
  Rate,
} from "antd";
import ImgCrop from "antd-img-crop";
import moment from "moment";
import {
  EditFilled,
  DeleteFilled,
  CheckSquareFilled,
  StopOutlined,
  NotificationOutlined,
  FileImageFilled,
  VideoCameraFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  list,
  add,
  edit,
  deleteTeacher,
  verifyTeacher,
  blockTeacher,
  sendNotification,
  editImage,
  editVideo,
} from "../../Api/Teacher";
import { allTypes } from "../../Api/Constants";
import { allCountries } from "../../Api/Country";
import { allCityByCountry } from "../../Api/City";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function OurTeam() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("teacher.fname"),
      dataIndex: "first_name",
      width: 100,
    },
    {
      title: t("teacher.lname"),
      dataIndex: "last_name",
      width: 100,
    },
    {
      title: t("teacher.gender"),
      dataIndex: "gender",
      width: 100,
    },
    {
      title: t("teacher.eamil"),
      dataIndex: "email",
      width: 100,
    },
    {
      title: t("teacher.phone"),
      dataIndex: "phone_number",
      width: 100,
    },
    {
      title: t("teacher.country"),
      dataIndex: "country_id",
      width: 100,
      render: (_) => _["arName"],
    },
    {
      title: t("teacher.city"),
      dataIndex: "city_id",
      width: 100,
      render: (_) => _["arName"],
    },
    {
      title: t("teacher.isVerify"),
      dataIndex: "isVerify",
      width: 100,
      render: (_, record) => (
        <Tag color={record.isVerify ? "green" : "red"}>
          {record.isVerify ? t("teacher.active") : t("teacher.notActive")}
        </Tag>
      ),
    },
    {
      title: t("teacher.block"),
      dataIndex: "isBlock",
      width: 100,
      render: (_, record) => (
        <Tag color={record.isBlock ? "red" : "green"}>
          {record.isBlock ? t("teacher.isBlock") : t("teacher.active")}
        </Tag>
      ),
    },
    {
      title: t("teacher.operations"),
      key: "actions",
      width: 350,
      fixed: "right",
      align: "center",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("teacher.editAcc")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(record)}
            >
              <EditFilled />
            </Button>
          </Tooltip>
          <Popconfirm
            title={t("teacher.sureDelete")}
            onConfirm={() => handleDelete(record._id)}
          >
            <Tooltip title={t("teacher.deleteAcc")}>
              <Button danger size="small">
                <DeleteFilled />
              </Button>
            </Tooltip>
          </Popconfirm>
          {!record.isVerify && (
            <Tooltip title={t("teacher.isVerify")}>
              <Button size="small" onClick={() => handelVerify(record._id)}>
                <CheckSquareFilled />
              </Button>
            </Tooltip>
          )}
          {record.isBlock ? (
            <Tooltip title={t("teacher.verify")}>
              <Button
                type="primary"
                ghost
                size="small"
                onClick={() => handelBlock(record._id, record.isBlock)}
              >
                <CheckSquareFilled />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("teacher.blockAcc")}>
              <Button
                size="small"
                danger
                onClick={() => handelBlock(record._id, record.isBlock)}
              >
                <StopOutlined />
              </Button>
            </Tooltip>
          )}
          <Tooltip title={t("teacher.notificationsSend")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleOpenNotficationModel(record._id)}
            >
              <NotificationOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={t("teacher.editImage")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleOpenImage(record)}
            >
              <FileImageFilled />
            </Button>
          </Tooltip>
          <Tooltip title={t("teacher.editvideo")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleOpenVideo(record)}
            >
              <VideoCameraFilled />
            </Button>
          </Tooltip>
          <Tooltip title={t("teacher.info")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleOpenInfo(record._id)}
            >
              <InfoCircleFilled />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [notficationModalVisible, setNotficationModalVisible] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [countries, setAllCountries] = useState([]);
  const [cities, setAllCities] = useState([]);
  const [teacherId, setTeacherId] = useState(null);
  const [teacher, setTeacher] = useState({});
  const [types, setTypes] = useState({});
  const [totalPage, setTotalPage] = useState(0);
  const [page, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [initData] = useState({ search_field: "email", search_value: "" });

  const onChangePage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const updateTable = async (data) => {
    try {
      setLoading(true);
      if (!data) data = initData;
      const res = await list(page, pageSize, data);
      setData(res.data.items);
      setTotalPage(res.data.pagination.totalElements);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handleAddOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      const data = form.getFieldsValue();
      if (teacherId) await edit(data, teacherId);
      else await add(data);
      setAddModalVisible(false);
      message.success(
        teacherId ? t("teacher.editTeacherDone") : t("teacher.addTeacherDone")
      );
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      if (error.response) message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleAddCancel = () => {
    setAddModalVisible(false);
    setTeacherId(null);
  };

  const handleAdd = () => {
    setTeacherId(null);
    setAddModalVisible(true);
  };

  const handleNotficationOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      const data = form.getFieldsValue();
      await sendNotification(teacherId, data);

      setNotficationModalVisible(false);
      message.success(t("teacher.notificationsSendDone"));
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleImageOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      if (fileList.length && fileList[0].uid !== -1) {
        const formData = new FormData();
        formData.set("image", fileList[0]);
        await editImage(teacherId, formData);
      }
      setImageModalVisible(false);
      message.success(t("teacher.editImageDone"));
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      if (error.response) message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleVideoOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      if (videoList.length && videoList[0].uid !== -1) {
        const formData = new FormData();
        formData.set("video", videoList[0]);
        await editVideo(teacherId, formData);
      }
      setVideoModalVisible(false);
      message.success(t("teacher.editvideoDone"));
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      if (error.response) message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleNotficationCancel = () => {
    setNotficationModalVisible(false);
    setTeacherId(null);
  };

  const handleImageCancel = () => {
    setImageModalVisible(false);
    setTeacherId(null);
  };

  const handleVideoCancel = () => {
    setVideoModalVisible(false);
    setTeacherId(null);
  };

  const handleInfoCancel = (_id) => {
    setInfoModalVisible(false);
  };

  const handleOpenNotficationModel = (id) => {
    setNotficationModalVisible(true);
    setTeacherId(id);
  };

  const handleOpenImage = (record) => {
    if (record.image) {
      setFileList([{ uid: -1, url: record.image }]);
      form.setFieldsValue({ image: "true" });
    } else {
      setFileList([]);
      form.setFieldsValue({ video: "" });
    }
    setImageModalVisible(true);
    setTeacherId(record._id);
  };

  const handleOpenVideo = (record) => {
    if (record.bio) {
      setVideoList([{ uid: -1, url: record.bio }]);
      form.setFieldsValue({ video: "true" });
    } else {
      setVideoList([]);
      form.setFieldsValue({ video: "" });
    }
    setVideoModalVisible(true);
    setTeacherId(record._id);
  };

  const handleOpenInfo = (_id) => {
    setInfoModalVisible(true);
    setTeacher(data.filter((e) => e._id === _id)[0]);
  };

  const updateCity = async (_id) => {
    const res = await allCityByCountry(_id);
    setAllCities(res.data.items);
  };

  const handleEdit = async ({
    _id,
    first_name,
    last_name,
    country_id: { _id: country_id },
    city_id: { _id: city_id },
    email,
    phone_number,
    about,
    schedule,
    spoken_language,
    interests,
    experience,
    type_id,
  }) => {
    setTeacherId(_id);
    const res = await allCityByCountry(country_id);
    setAllCities(res.data.items);
    form.setFieldsValue({
      first_name,
      last_name,
      country_id,
      city_id,
      email,
      phone_number,
      about,
      schedule,
      spoken_language,
      interests,
      experience,
      type_id: type_id ? type_id.map((e) => e._id) : [],
    });
    setAddModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteTeacher(id);
      updateTable();
      message.success(t("teacher.deleteTeacherDone"));
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handelVerify = async (id) => {
    try {
      await verifyTeacher(id);
      updateTable();
      message.success(t("teacher.verifyAccDone"));
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handelBlock = async (id, isBlock) => {
    try {
      await blockTeacher(id, isBlock);
      updateTable();
      message.success(
        isBlock ? t("teacher.verifyDone") : t("teacher.blockAccDone")
      );
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    !newFileList.length && form.setFieldsValue({ image: "" });
    setFileList(newFileList);
  };

  const onChangeVideo = ({ fileList: newFileList }) => {
    !newFileList.length && form.setFieldsValue({ video: "" });
    setVideoList(newFileList);
  };

  const beforeUpload = (file) => {
    setFileList([...fileList, file]);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    form.setFieldsValue({ image: "True" });
    reader.onloadend = () => {
      file.url = reader.result;
      setFileList([file]);
    };
    return false;
  };

  const beforeUploadVideo = (file) => {
    setVideoList([...fileList, file]);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    form.setFieldsValue({ video: "True" });
    reader.onloadend = () => {
      file.url = reader.result;
      setVideoList([file]);
    };
    return false;
  };

  const handleSearch = () => {
    updateTable(form1.getFieldsValue());
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
        const res = await allCountries();
        setAllCountries(res.data.items);
        const resTypes = await allTypes();
        setAllCountries(res.data.items);
        setTypes(resTypes.data.items);
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, [page, pageSize]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <>
      <Row justify="end">
        <Button
          loading={loading}
          className="ant-btn-big"
          onClick={handleAdd}
          type="primary"
        >
          {t("teacher.addTeacher")}
        </Button>
      </Row>
      <br />
      <Form form={form1}>
        <Row justify="center">
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              name="search_field"
              label={t("teacher.searchOption")}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio style={radioStyle} value="first_name">
                  {t("teacher.fname")}
                </Radio>
                <Radio style={radioStyle} value="last_name">
                  {t("teacher.lname")}
                </Radio>
                <Radio style={radioStyle} value="email">
                  {t("teacher.email")}
                </Radio>
                <Radio style={radioStyle} value="phone_number">
                  {t("teacher.phone")}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              name="search_value"
              label={t("teacher.value")}
              //rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={handleSearch}>
                {t("teacher.search")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={false}
        scroll={{ x: "1300" }}
      />
      <br />
      <Pagination
        current={page}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onChangePage}
        total={totalPage}
      />
      <Modal
        title={teacherId ? t("teacher.editTeacher") : t("teacher.addTeacher")}
        visible={addModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        destroyOnClose
        centered
        okText={teacherId ? t("teacher.edit") : t("teacher.edit")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="first_name"
              label={t("teacher.fname")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="last_name"
              label={t("teacher.lname")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="country_id"
              label={t("teacher.country")}
              rules={[{ required: true }]}
            >
              <Select onChange={updateCity}>
                {countries.map((ele) => {
                  return (
                    <Option
                      key={ele["_id"]}
                      value={ele["_id"]}
                    >{`${ele["flag"]} ${ele["code"]} ${ele["name"]}`}</Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="phone_number"
              label={t("teacher.phone")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="city_id"
              label={t("teacher.city")}
              rules={[{ required: true }]}
            >
              <Select>
                {cities.map((ele) => {
                  return (
                    <Option key={ele["_id"]} value={ele["_id"]}>
                      {ele["name"]}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            {!teacherId ? (
              <>
                <Form.Item
                  name="email"
                  label={t("teacher.email")}
                  rules={[{ required: true, type: "email" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t("teacher.password")}
                  rules={[{ required: true }]}
                >
                  <Input type="password" />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  name="about"
                  label={t("teacher.about")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="schedule"
                  label={t("teacher.schedule")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="spoken_language"
                  label={t("teacher.language")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="interests"
                  label={t("teacher.interests")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="experience"
                  label={t("teacher.experience")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="type_id"
                  label={t("teacher.chatType")}
                  rules={[{ required: true }]}
                >
                  <Select
                    defaultValue
                    mode="multiple"
                    style={{ width: "100%" }}
                  >
                    {types.map((ele) => {
                      return (
                        <Option key={ele["_id"]} value={ele["_id"]}>
                          {ele["arName"]}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            )}
          </Form>
        </Spin>
      </Modal>

      <Modal
        title={t("teacher.notificationsSend")}
        visible={notficationModalVisible}
        onOk={handleNotficationOk}
        onCancel={handleNotficationCancel}
        destroyOnClose
        centered
        okText={t("teacher.send")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="sendNotficationForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="title"
              label={t("teacher.title")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="msg"
              label={t("teacher.msg")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title={t("teacher.editImage")}
        visible={imageModalVisible}
        onOk={handleImageOk}
        onCancel={handleImageCancel}
        destroyOnClose
        centered
        okText={t("teacher.edit")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="uploadImageForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="image"
              label="Image"
              rules={[{ required: true, type: "string", min: 1 }]}
            >
              <Input hidden />
              <ImgCrop rotate>
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  beforeUpload={beforeUpload}
                  showUploadList={{ showPreviewIcon: false }}
                >
                  {fileList.length < 1 && "+ Upload"}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title={t("teacher.editvideo")}
        visible={videoModalVisible}
        onOk={handleVideoOk}
        onCancel={handleVideoCancel}
        destroyOnClose
        centered
        okText={t("teacher.edit")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="uploadVideoForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="video"
              label="video"
              rules={[{ required: true, type: "string", min: 1 }]}
            >
              <Input de hidden />
              <Upload
                listType="picture-card"
                accept="video/*"
                fileList={videoList}
                onChange={onChangeVideo}
                beforeUpload={beforeUploadVideo}
                showUploadList={{ showPreviewIcon: false }}
              >
                {videoList.length < 1 && "+ Upload"}
              </Upload>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title={t("teacher.infoTeacher")}
        visible={infoModalVisible}
        footer={""}
        onCancel={handleInfoCancel}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label={t("teacher.image")}>
            <Avatar size="large" src={teacher.image}></Avatar>
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.fname")}>
            {teacher.first_name}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.lname")}>
            {teacher.last_name}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.gender")}>
            {teacher.gender}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.country")}>
            {teacher.country_id && teacher.country_id.arName}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.city")}>
            {teacher.city_id && teacher.city_id.arName}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.email")}>
            {teacher.email}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.phone")}>
            {teacher.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.rate")}>
            <Rate disabled defaultValue={teacher.rate} />
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.schedule")}>
            {teacher.schedule}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.language")}>
            {teacher.spoken_language}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.wallet")}>
            {teacher.wallet}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.interests")}>
            {teacher.interests}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.experience")}>
            {teacher.experience}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.chatType")}>
            {teacher.type_id
              ? teacher.type_id.map((e) => e.arName + ", ")
              : t("teacher.noChatType")}
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.video")}>
            <a target="_blank" rel="noreferrer" href={teacher.bio}>
              {t("teacher.videoWatch")}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label={t("teacher.date")}>
            {moment(teacher.createdAt).format("YYYY-MM-DD")}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  );
}
export default OurTeam;
