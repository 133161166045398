import { useContext } from "react";
import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Spin,
  Space,
  message,
  Popconfirm,
  Tooltip,
  Pagination,
  Checkbox,
  Col,
} from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { list, add, edit, deleteAdmin } from "../../Api/Admin";
import { UserContext } from "./../../context";
import { useTranslation } from "react-i18next";

function Admins() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const columns = [
    {
      title: t("admins.name"),
      dataIndex: "full_name",
      width: 100,
    },
    {
      title: t("admins.eamil"),
      dataIndex: "email",
      width: 100,
    },
    {
      title: t("admins.phone"),
      dataIndex: "phone_number",
      width: 100,
    },
    {
      title: t("admins.operations"),
      key: "actions",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space>
          <Tooltip title={t("admins.editAcc")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(record)}
            >
              <EditFilled />
            </Button>
          </Tooltip>
          <Popconfirm
            title={t("admins.sureDelete")}
            onConfirm={() => handleDelete(record._id)}
          >
            <Tooltip title={t("admins.deleteAcc")}>
              <Button danger size="small">
                <DeleteFilled />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setCurrentPage] = useState(1);
  const [initialValue, setInitialValue] = useState([]);
  const [pageSize] = useState(10);
  const permissions = [
    t("admins.permissions.statistics"),
    t("admins.permissions.admins"),
    t("admins.permissions.teachers"),
    t("admins.permissions.students"),
    t("admins.permissions.welcomePages"),
    t("admins.permissions.staticPages"),
    t("admins.permissions.helpPages"),
    t("admins.permissions.homePages"),
    t("admins.permissions.levels"),
    t("admins.permissions.subscriptions"),
    t("admins.permissions.typeoftests"),
    t("admins.permissions.typeofcomplaints"),
    t("admins.permissions.languages"),
    t("admins.permissions.countries"),
    t("admins.permissions.cities"),
    t("admins.permissions.notificationsSent"),
    t("admins.permissions.notificationsAdmin"),
    t("admins.permissions.Ratings"),
    t("admins.permissions.complaints"),
    t("admins.permissions.ads"),
    t("admins.permissions.financialAccounts"),
    t("admins.permissions.chats"),
    t("admins.permissions.coupons"),
    t("admins.permissions.settings"),
  ];
  const onChangePage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await list("page", pageSize);
      setData(res.data.items);
      setTotalPage(res.data.pagination.totalElements);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handleAddOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      const data = form.getFieldsValue();
      let finalRoles = 0;
      for (let i = 0; i < data.roles.length; i++) {
        finalRoles |= 1 << data.roles[i];
      }
      if (adminId)
        await edit({ ...data, roles: finalRoles, _id: adminId }, adminId);
      else await add({ ...data, roles: finalRoles });
      setAddModalVisible(false);
      message.success(
        adminId ? t("admins.editAdminDone") : t("admins.addAdminDone")
      );
      if (adminId === user._id) window.location.reload();
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      if (error.response) message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleAddCancel = () => {
    setAddModalVisible(false);
    setAdminId(null);
  };

  const handleAdd = () => {
    setAdminId(null);
    setAddModalVisible(true);
  };

  const handleEdit = async ({ _id, full_name, email, phone_number, roles }) => {
    const initialValues = [];
    for (let i = 0; i < permissions.length; i++) {
      if (roles & (1 << i)) {
        initialValues.push(i);
      }
    }
    setInitialValue(initialValues);
    setAdminId(_id);
    form.setFieldsValue({
      full_name,
      email,
      phone_number,
    });
    setAddModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteAdmin(id);
      updateTable();
      message.success(t("admins.deleteAdminDone"));
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, [page, pageSize]);

  return (
    <>
      <Row justify="end">
        <Button
          loading={loading}
          className="ant-btn-big"
          onClick={handleAdd}
          type="primary"
        >
          {t("admins.addAdmin")}
        </Button>
      </Row>
      <br />
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        scroll={{ x: "500" }}
        pagination={false}
      />
      <br />
      <Pagination
        current={page}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onChangePage}
        total={totalPage}
      />

      <Modal
        title={adminId ? t("admins.editAdmin") : t("admins.addAdmin")}
        visible={addModalVisible}
        onOk={handleAddOk}
        onCancel={() => {
          handleAddCancel();
          setInitialValue([]);
        }}
        destroyOnClose
        centered
        okText={adminId ? t("admins.edit") : t("admins.add")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="full_name"
              label={t("admins.name")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label={t("admins.eamil")}
              rules={[{ required: true, type: "email" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="phone_number"
              label={t("admins.phone")}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^([\+]|00)?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                  message: t("validation.phone"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            {!adminId && (
              <Form.Item
                name="password"
                label={t("admins.password")}
                rules={[{ required: true }]}
              >
                <Input type="password" />
              </Form.Item>
            )}
            <Form.Item
              name="roles"
              label={t("admins.permissions.permissions")}
              initialValue={initialValue}
            >
              <Checkbox.Group>
                <Row>
                  {permissions.map((element, index) => (
                    <Col key={index} span={8}>
                      <Checkbox style={{ marginRight: 10 }} value={index}>
                        {element}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default Admins;
