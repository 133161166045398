import instance from './index';

export const list = (page, pageSize) => {
  return instance.get(`admin/list?page=${page - 1}&limit=${pageSize}`, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const add = (data) => {
  return instance.post('admin/add', data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
export const edit = (data, id) => {
  console.log(data, id);
  return instance.post('admin/update/' + id, data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
export const editPass = (data, id) => {
  console.log(data, id);
  return instance.post('admin/password', data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
export const deleteAdmin = (id) => {
  return instance.post(
    `admin/delete/${id}`,
    {},
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    }
  );
};
