import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Spin,
  Space,
  message,
  Popconfirm,
  Tooltip,
} from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { list, add, edit, deleteLanguages } from "../../Api/Language";
import { useTranslation } from "react-i18next";

function Language() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("language.arName"),
      dataIndex: "arName",
    },
    {
      title: t("language.enName"),
      dataIndex: "enName",
    },
    {
      title: t("language.operations"),
      key: "actions",
      width: "fit-content",
      align: "center",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("language.editLanguage")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(record)}
            >
              <EditFilled />
            </Button>
          </Tooltip>
          <Popconfirm
            title={t("language.sureDelete")}
            onConfirm={() => handleDelete(record._id)}
          >
            <Tooltip title={t("language.deleteLanguage")}>
              <Button danger size="small">
                <DeleteFilled />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [adminId, setAdminId] = useState(null);

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await list();
      console.log(res.data.items);
      setData(res.data.items);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  const handleAddOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      const data = form.getFieldsValue();
      if (adminId) await edit(data, adminId);
      else await add(data);
      setAddModalVisible(false);
      message.success(
        adminId ? t("language.editLanguageDone") : t("language.addLanguageDone")
      );
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
      setModalLoading(false);
    }
  };

  const handleAddCancel = () => {
    setAddModalVisible(false);
    setAdminId(null);
  };

  const handleAdd = () => {
    setAdminId(null);
    setAddModalVisible(true);
  };

  const handleEdit = async ({ _id, enName, arName }) => {
    setAdminId(_id);
    form.setFieldsValue({
      enName,
      arName,
    });
    setAddModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteLanguages(id);
      updateTable();
      message.success(t("language.deleteLanguageDone"));
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  useEffect(() => {
    const update = async () => {
      try {
        updateTable();
      } catch (error) {
        message.error(t("errors.server"));
      }
    };
    update();
  }, []);

  return (
    <>
      <Row justify="end">
        <Button
          loading={loading}
          className="ant-btn-big"
          onClick={handleAdd}
          type="primary"
        >
          {t("language.addLanguage")}
        </Button>
      </Row>
      <br />
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={{ showSizeChanger: false }}
      />

      <Modal
        title={adminId ? t("language.editLanguage") : t("language.addLanguage")}
        visible={addModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        destroyOnClose
        centered
        okText={adminId ? t("language.edit") : t("language.add")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="arName"
              label={t("language.arName")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="enName"
              label={t("language.enName")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default Language;
