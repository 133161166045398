import instance from './index';

export const home = () => {
  return instance.get('constant/home', {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const homePage = (id) => {
  return instance.get('constant/home/' + id, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const edit = (data, id) => {
  return instance.post('constant/home/' + id, data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
