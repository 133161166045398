import instance from './index';

export const allTypes = () => {
  return instance.get('constant/subject-type', {
    headers: {
      token: localStorage.getItem('token'),
      'Accept-Language': localStorage.getItem('i18nextLng'),
    },
  });
};
