import { useEffect, useContext } from "react";
import { Row, Col, Button, Form, Input, Spin, message } from "antd";
import { useState } from "react";
import { editProfile } from "../../Api/Auth";
import { editPass } from "../../Api/Admin";
import { UserContext } from "../../context";
import Modal from "antd/lib/modal/Modal";
import { useTranslation } from "react-i18next";
function Profile() {
  const { t } = useTranslation();
  const { user, updateUser } = useContext(UserContext);
  const [form] = Form.useForm();
  const [formPass] = Form.useForm();
  const [modalLoading, setModalLoading] = useState(false);
  const [passModalVisible, setPassModalVisible] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  useEffect(() => form.setFieldsValue({ ...user }), [form, user]);
  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        const objData = form.getFieldsValue();
        console.log(objData);
        return editProfile(objData);
      })
      .then((res) => {
        updateUser(res.data.items);
        setSaveLoading(false);
        message.success(t("userProfile.editDone"));
      })
      .catch((err) => {
        setSaveLoading(false);
        if (err.response) message.error(err.response.data.message);
      });
  };

  const handleOk = async () => {
    try {
      await formPass.validateFields();
      setModalLoading(true);
      const data = formPass.getFieldsValue();
      await editPass(data);
      setModalLoading(false);
      message.success(t("userProfile.editDone"));
      setPassModalVisible(false);
    } catch (error) {
      if (error.response) message.error(error.response.data.message);
      setModalLoading(false);
    }
  };

  const handleCancel = () => {
    setPassModalVisible(false);
  };
  return (
    <Spin spinning={!user}>
      <Row justify="end">
        <Button
          className="ant-btn-big"
          type="link"
          onClick={() => setPassModalVisible(true)}
        >
          {t("userProfile.changePass")}
        </Button>
        <Button
          loading={saveLoading}
          className="ant-btn-big"
          type="primary"
          onClick={onFinish}
        >
          {t("userProfile.save")}
        </Button>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            name="ourStory"
            labelCol={{ span: 24 }}
            colon={false}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              label={t("userProfile.email")}
              rules={[{ required: true, type: "email" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="full_name"
              label={t("userProfile.fullName")}
              rules={[{ required: true, max: 20 }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone_number"
              label={t("userProfile.phone")}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^([\+]|00)?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                  message: t("userProfile.notPhone"),
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Modal
        title={t("userProfile.editPass")}
        visible={passModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        centered
        okText={t("userProfile.edit")}
        cancelText={t("userProfile.cancel")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={formPass}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              label={t("userProfile.oldPass")}
              name="old_password"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("userProfile.newPass")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label={t("userProfile.confirmNewPass")}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t("userProfile.mustSame"));
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </Spin>
  );
}

export default Profile;
