import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Spin,
  Space,
  message,
  Popconfirm,
  Tooltip,
  Checkbox,
  DatePicker,
  InputNumber,
  Tag,
} from "antd";
import moment from "moment";
import { EditFilled, DeleteFilled, InfoCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  list,
  couponDetails,
  deleteCoupon,
  add,
  edit,
  couponUse,
} from "../../Api/Coupons";

function Coupons() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("coupons.coupon"),
      dataIndex: "coupon",
    },
    {
      title: t("coupons.days"),
      dataIndex: "days",
    },
    {
      title: t("coupons.discountRate"),
      dataIndex: "discount_rate",
    },
    {
      title: t("coupons.dtFrom"),
      dataIndex: "dt_from",
      render: (_) => moment(_).format("YYYY-MM-DD, HH:mm:ss"),
    },
    {
      title: t("coupons.dtTo"),
      dataIndex: "dt_to",
      render: (_) => moment(_).format("YYYY-MM-DD, HH:mm:ss"),
    },
    {
      title: t("coupons.isActive"),
      dataIndex: "isActive",
      render: (_) => (
        <Tag color={_ ? "green" : "red"}>
          {_ ? t("coupons.active") : t("coupons.notActive")}
        </Tag>
      ),
    },
    {
      title: t("coupons.msg"),
      dataIndex: "msg",
    },
    {
      title: t("coupons.operations"),
      key: "actions",
      width: "fit-content",
      align: "center",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("coupons.editCoupon")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(record)}
            >
              <EditFilled />
            </Button>
          </Tooltip>
          <Tooltip title={t("coupons.useCoupons")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleInfo(record.coupon)}
            >
              <InfoCircleFilled />
            </Button>
          </Tooltip>
          <Popconfirm
            title={t("coupons.sureDelete")}
            onConfirm={() => handleDelete(record._id)}
          >
            <Tooltip title={t("coupons.deleteCoupon")}>
              <Button danger size="small">
                <DeleteFilled />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [couponsId, setCouponsId] = useState(null);
  const [couponName, setCouponName] = useState(null);
  const [checked, setChecked] = useState(false);

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await list();
      setData(res.data.items);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };
  const handleAddOk = async () => {
    try {
      await form.validateFields();
      setModalLoading(true);
      let objData = form.getFieldsValue();
      objData.isActive = checked;
      objData = {
        ...objData,
        dt_from: moment(objData.dt_from).format("YYYY-MM-DD"),
        dt_to: moment(objData.dt_to).format("YYYY-MM-DD"),
      };
      console.log(objData);
      if (couponsId) await edit(objData, couponsId);
      else await add(objData);
      setAddModalVisible(false);
      message.success(
        couponsId ? t("coupons.editCouponDone") : t("coupons.addCouponDone")
      );
      await updateTable();
      setModalLoading(false);
    } catch (error) {
      if (error.response) message.error(t("errors.server"));
      setModalLoading(false);
    }
  };
  const handleAddCancel = () => {
    setAddModalVisible(false);
    setCouponsId(null);
  };
  const handleAdd = () => {
    setCouponsId(null);
    setAddModalVisible(true);
  };
  const handleInfoCancel = () => {
    setCouponModalVisible(false);
  };
  const handleInfo = async (coupon) => {
    try {
      const response = await couponUse(coupon);
      setCouponName(coupon);
      console.log(response.data.items);
      setCouponData(response.data.items);
      setCouponModalVisible(true);
    } catch (error) {
      console.log(error);
      message.error(t("errors.server"));
    }
  };
  const handleEdit = async (a) => {
    try {
      const {
        data: { items },
      } = await couponDetails(a._id);
      setChecked(items.isActive);
      form.setFieldsValue({
        days: items.days,
        dt_from: moment(items.dt_from),
        dt_to: moment(items.dt_to),
        coupon: items.coupon,
        msg: items.msg,
        discount_rate: items.discount_rate,
      });
    } catch (error) {
      message.error(t("errors.server"));
    }
    setCouponsId(a._id);
    setAddModalVisible(true);
  };
  const handleDelete = async (id) => {
    try {
      await deleteCoupon(id);
      updateTable();
      message.success(t("coupons.deleteCouponDone"));
    } catch (error) {
      message.error(t("errors.server"));
    }
  };

  useEffect(() => updateTable(), []);

  return (
    <>
      <Row justify="end">
        <Button
          loading={loading}
          className="ant-btn-big"
          onClick={handleAdd}
          type="primary"
        >
          {t("coupons.addCoupon")}
        </Button>
      </Row>
      <br />
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={{ showSizeChanger: false }}
      />

      <Modal
        title={couponsId ? t("coupons.editCoupon") : t("coupons.addCoupon")}
        visible={addModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        destroyOnClose
        centered
        okText={couponsId ? t("coupons.edit") : t("coupons.add")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="dt_from"
              label={t("coupons.dtFrom")}
              rules={[{ required: true }]}
            >
              <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="dt_to"
              label={t("coupons.dtTo")}
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="coupon"
              label={t("coupons.coupon")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="msg"
              label={t("coupons.msg")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="discount_rate"
              label={t("coupons.discountRate")}
              rules={[{ required: true, type: "number", min: 0, max: 1 }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="days"
              label={t("coupons.days")}
              rules={[{ required: true, type: "integer", min: 0 }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label={t("coupons.couponStatus")}
              rules={[{ required: true }]}
            >
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              >
                {t("coupons.active")}
              </Checkbox>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <Modal
        title={`${t("coupons.useCoupons")} ${couponName}`}
        visible={couponModalVisible}
        onCancel={handleInfoCancel}
        destroyOnClose
        confirmLoading={modalLoading}
        footer={null}
      >
        <Spin spinning={modalLoading}>
          <Table
            dataSource={couponData}
            columns={[
              {
                title: t("coupons.user"),
                dataIndex: "user_id",
                render: (_) => _.username,
              },
              {
                title: t("coupons.value"),
                dataIndex: "amount",
              },
              {
                title: t("coupons.discountRate"),
                dataIndex: "discount",
              },
              {
                title: t("coupons.total"),
                dataIndex: "total",
              },
              {
                title: t("coupons.dtFrom"),
                dataIndex: "from_date",
                render: (_) => moment(_).format("YYYY-MM-DD"),
              },
              {
                title: t("coupons.dtTo"),
                dataIndex: "to_date",
                render: (_) => moment(_).format("YYYY-MM-DD"),
              },
            ]}
            bordered
            rowKey="_id"
            pagination={{ showSizeChanger: false }}
          />
        </Spin>
      </Modal>
    </>
  );
}
export default Coupons;
