import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Spin,
  Space,
  message,
  Popconfirm,
  Tooltip,
  Upload,
  Avatar,
} from "antd";
import ImgCrop from "antd-img-crop";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  listWelcome,
  deletePage,
  add,
  welcomePage,
  edit,
} from "../../Api/WelcomePage";
import { useTranslation } from "react-i18next";

function WelcomePage() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("welcomePages.icon"),
      dataIndex: "icon",
      render: (_) => <Avatar src={_} />,
      textAlign: "center",
    },
    {
      title: t("welcomePages.enTitle"),
      dataIndex: "enTitle",
    },
    {
      title: t("welcomePages.arTitle"),
      dataIndex: "arTitle",
    },
    {
      title: t("welcomePages.enDescription"),
      dataIndex: "enDescription",
    },
    {
      title: t("welcomePages.arDescription"),
      dataIndex: "arDescription",
    },
    {
      title: t("welcomePages.operations"),
      key: "actions",
      width: "fit-content",
      align: "center",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("welcomePages.editPage")}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(record)}
            >
              <EditFilled />
            </Button>
          </Tooltip>
          <Popconfirm
            title={t("welcomePages.sureDelete")}
            onConfirm={() => handleDelete(record._id)}
          >
            <Tooltip title={t("welcomePages.pageDelete")}>
              <Button danger size="small">
                <DeleteFilled />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [welcomePageId, setWelcomePageId] = useState(null);

  const updateTable = async () => {
    try {
      setLoading(true);
      const res = await listWelcome();
      setData(res.data.items);
      setLoading(false);
    } catch (error) {
      message.error(t("errors.server"));
    }
  };
  const handleAddOk = async () => {
    form.setFieldsValue({ image: fileList.length === 0 ? "" : "True" });
    try {
      await form.validateFields();
      setModalLoading(true);
      const formData = new FormData();
      const objData = form.getFieldsValue();
      for (let key in objData) {
        formData.append(key, objData[key]);
      }
      if (fileList[0].uid !== -1) formData.set("image", fileList[0]);
      else formData.delete("image");
      if (welcomePageId) await edit(formData, welcomePageId);
      else await add(formData);
      setAddModalVisible(false);
      message.success(
        welcomePageId
          ? t("welcomePages.editPageDone")
          : t("welcomePages.addPageDone")
      );
      await updateTable();
      setModalLoading(false);
      setFileList([]);
    } catch (error) {
      if (error.response) message.error(t("errors.server"));
      setModalLoading(false);
    }
  };
  const handleAddCancel = () => {
    setAddModalVisible(false);
    setWelcomePageId(null);
    setFileList([]);
  };
  const handleAdd = () => {
    setWelcomePageId(null);
    setAddModalVisible(true);
  };
  const handleEdit = async (a) => {
    try {
      const {
        data: { items },
      } = await welcomePage(a._id);
      form.setFieldsValue({
        enTitle: items.enTitle,
        arTitle: items.arTitle,
        arDescription: items.arDescription,
        enDescription: items.enDescription,
        icon: items.icon,
      });
      setFileList([{ uid: -1, url: items.icon }]);
    } catch (error) {
      message.error(t("errors.server"));
    }
    setWelcomePageId(a._id);
    setAddModalVisible(true);
  };
  const handleDelete = async (id) => {
    try {
      await deletePage(id);
      updateTable();
      message.success(t(""));
    } catch (error) {
      message.error(t("welcomePages.deletePageDone"));
    }
  };
  const onChange = ({ fileList: newFileList }) => {
    !newFileList.length && form.setFieldsValue({ image: "" });
    setFileList(newFileList);
  };
  const beforeUpload = (file) => {
    setFileList([...fileList, file]);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    form.setFieldsValue({ image: "True" });
    reader.onloadend = () => {
      file.url = reader.result;
      setFileList([file]);
    };
    return false;
  };
  useEffect(() => updateTable(), []);

  return (
    <>
      <Row justify="end">
        <Button
          loading={loading}
          className="ant-btn-big"
          onClick={handleAdd}
          type="primary"
        >
          {t("welcomePages.addPage")}
        </Button>
      </Row>
      <br />
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        bordered
        rowKey="_id"
        pagination={{ showSizeChanger: false }}
      />

      <Modal
        title={
          welcomePageId
            ? t("welcomePages.editWelcomePage")
            : t("welcomePages.addWelcomePage")
        }
        visible={addModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        destroyOnClose
        centered
        okText={welcomePageId ? t("welcomePages.edit") : t("welcomePages.add")}
        confirmLoading={modalLoading}
      >
        <Spin spinning={modalLoading}>
          <Form
            preserve={false}
            form={form}
            name="addTeacherForm"
            labelCol={{ span: 24 }}
          >
            <Form.Item
              name="enTitle"
              label={t("welcomePages.enTitle")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="arTitle"
              label={t("welcomePages.arTitle")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="arDescription"
              label={t("welcomePages.enDescription")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="enDescription"
              label={t("welcomePages.arDescription")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="image"
              label={t("welcomePages.image")}
              rules={[{ required: true }]}
            >
              <Input hidden />
              <ImgCrop rotate>
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  beforeUpload={beforeUpload}
                  showUploadList={{ showPreviewIcon: false }}
                >
                  {fileList.length < 1 && "+ Upload"}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default WelcomePage;
