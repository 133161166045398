import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import HomeApi from "../../Api/Home";
import { Statistic, Row, Col, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    const getData = () => {
      setLoading(true);
      const data = {};
      HomeApi.counters()
        .then((res) => {
          data.counters = res.data.items;
          return HomeApi.userByGender();
        })
        .then((res) => {
          data.userByGender = res.data.items;
          return HomeApi.userByAges();
        })
        .then((res) => {
          data.userByAges = res.data.items;
          return HomeApi.userByCountries();
        })
        .then((res) => {
          data.userByCountries = res.data.items;
          return HomeApi.userByConversationType();
        })
        .then((res) => {
          data.userByConversationType = res.data.items;
          return HomeApi.userByNewUsers();
        })
        .then((res) => {
          data.userByNewUsers = res.data.items;
          return HomeApi.userBySubscribeToFinish();
        })
        .then((res) => {
          data.userBySubscribeToFinish = res.data.items;
          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.data.statusCode === 400) history.replace("/login");
        });
    };
    getData();
  }, [history]);
  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Col>
          <LoadingOutlined style={{ fontSize: 50 }} spin />
        </Col>
      </Row>
    );
  }
  return (
    <Row gutter={[50, 50]}>
      <Col span={24}>
        <h1>{t("home.statistics")}</h1>
      </Col>
      <Col span={12}>
        <Statistic
          title={t("home.#ofusersapp")}
          value={data.counters.total_users}
        />
      </Col>
      <Col span={12}>
        <Statistic
          title={t("home.#ofusersinsubscriptions")}
          value={data.counters.total_active_users}
        />
      </Col>
      <Col span={12}>
        <Statistic
          title={t("home.#ofteachersapp")}
          value={data.counters.total_teachers}
        />
      </Col>
      <Col span={12}>
        <Statistic
          title={t("home.#ofchats")}
          value={data.counters.total_chats}
        />
      </Col>
      <Col span={12}>
        <h1>{t("home.chartusersgender")}</h1>
        <ResponsiveContainer style={{ height: "30vh" }}>
          <BarChart
            style={{ direction: "ltr" }}
            data={data.userByGender}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="rgb(0, 196, 159)" />
          </BarChart>
        </ResponsiveContainer>
      </Col>
      <Col span={12}>
        <h1>{t("home.chartusersage")}</h1>
        <ResponsiveContainer style={{ height: "30vh" }}>
          <BarChart
            style={{ direction: "ltr" }}
            data={data.userByAges}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Col>
      <Col span={12}>
        <h1>{t("home.chartuserscountries")}</h1>
        <ResponsiveContainer style={{ height: "30vh" }}>
          <BarChart
            style={{ direction: "ltr" }}
            data={data.userByCountries}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#2ecc71" />
          </BarChart>
        </ResponsiveContainer>
      </Col>
      <Col span={12}>
        <h1>{t("home.chartchatsubjects")}</h1>
        <ResponsiveContainer style={{ height: "30vh" }}>
          <BarChart
            style={{ direction: "ltr" }}
            data={data.userByConversationType}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#e74c3c" />
          </BarChart>
        </ResponsiveContainer>
      </Col>
      <Col span={12}>
        <h1>{t("home.usernew")}</h1>
        <Table
          rowKey="_id"
          dataSource={data.userByNewUsers}
          columns={[
            {
              title: t("home.username"),
              dataIndex: "username",
            },
            {
              title: t("home.email"),
              dataIndex: "email",
            },
            {
              title: t("home.createAt"),
              dataIndex: "createAt",
              render: (createAt) =>
                `${moment(createAt).format("YYYY-MM-DD, HH:mm:ss")}`,
            },
          ]}
        />
      </Col>
      <Col span={12}>
        <h1>{t("home.usersubscriptions")}</h1>
        <Table
          rowKey="_id"
          dataSource={data.userBySubscribeToFinish}
          columns={[
            {
              title: t("home.username"),
              dataIndex: "username",
            },
            {
              title: t("home.email"),
              dataIndex: "email",
            },
            {
              title: t("home.createAt"),
              dataIndex: "createAt",
              render: (createAt) =>
                `${moment(createAt).format("YYYY-MM-DD, HH:mm:ss")}`,
            },
          ]}
          pagination={{ pageSize: 5 }}
        />
      </Col>
    </Row>
  );
}
export default Home;
