import instance from "./index";

export const listAdv = () => {
  return instance.get("constant/adv", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
export const adv = (id) => {
  return instance.get("constant/adv/" + id, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
export const add = (data) => {
  return instance.post("constant/adv", data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
export const edit = (data, id) => {
  return instance.post("constant/adv/" + id, data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
export const deletePage = (id) => {
  return instance.post(`constant/adv_delete/${id}`, null, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
export const activeOrDeActive = (id, isActive) => {
  return instance.post(
    `constant/adv-active/${id}`,
    { isActive: !isActive },
    {
      headers: {
        token: localStorage.getItem("token"),
      },
    }
  );
};