import instance from "./index";

export const list = () => {
  return instance.get("constant/complains_type", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const add = (data) => {
  return instance.post("constant/complains_type", data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const edit = (data, id) => {
  console.log(data, id);
  return instance.post("constant/complains_type/" + id, data, {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

export const deleteComplains = (id) => {
  return instance.post(
    `constant/complains_type_delete/${id}`,
    {},
    {
      headers: {
        token: localStorage.getItem("token"),
      },
    }
  );
};
