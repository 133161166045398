import "antd/dist/antd.css";
import "./App.css";
import {
  Layout,
  Row,
  Col,
  Button,
  Avatar,
  Tooltip,
  ConfigProvider,
} from "antd";
import { LoadingOutlined, LogoutOutlined } from "@ant-design/icons";

import { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { I18nextProvider, useTranslation } from "react-i18next";
import ar_EG from "antd/lib/locale/ar_EG";
import en_US from "antd/lib/locale/en_US";

import { UserContext } from "./../context";
import SideMenu from "../Common/SideMenu";
import LoginPage from "../Pages/Login";
import Teacher from "../Pages/Teacher";
import Countries from "../Pages/Countries";
import Cities from "../Pages/Cities";
import OldNotification from "../Pages/OldNotification";
import AdminNotification from "../Pages/AdminNotification";
import Users from "../Pages/Users";
import Home from "../Pages/Home";
import WelcomePage from "../Pages/WelcomePage";
import Authenticate from "../Utils/Authenticate";
import PrivateRoute from "../Utils/PrivateRoute";
import Admin from "../Pages/Admin";
import Complains from "../Pages/Complains";
import ComplainsType from "../Pages/ComplainsType";
import Language from "../Pages/Language";
import Subscription from "../Pages/Subscription";
import Adv from "../Pages/Adv";
import HomePage from "../Pages/HomePage";
import Profile from "../Pages/Profile";
import Level from "../Pages/Level";
import StaticPages from "../Pages/StaticPages";
import SubjectType from "../Pages/SubjectType";
import HelpPage from "../Pages/HelpPage";
import Rate from "../Pages/Rate";
import Payment from "../Pages/Payment";
import Conversation from "../Pages/Conversation";
import Coupons from "../Pages/Coupons";
import Settings from "../Pages/Settings";
import { Link } from "react-router-dom";

const { Header, Content } = Layout;

function App() {
  const history = useHistory();
  const [user, updateUser] = useState(undefined);
  const { t, i18n } = useTranslation();
  const lang = i18n.languages[0];
  const currentUser = { permissions: user && user.roles };

  const [authChecked, setAuthChecked] = useState(false);
  useEffect(() => {
    Authenticate.checkAuth((items) => {
      setAuthChecked(true);
      updateUser(items);
    });
  }, []);

  const logout = () =>
    Authenticate.signout(() => {
      localStorage.removeItem("token");
      window.location.reload();
    }, user._id);

  const changeLanguage = (lng) => i18n.changeLanguage(lng);
  if (!authChecked) {
    return (
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Col>
          <LoadingOutlined style={{ fontSize: 50 }} spin />
        </Col>
      </Row>
    );
  }
  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider
        locale={lang === "ar" ? ar_EG : en_US}
        direction={lang === "ar" ? "rtl" : "ltr"}
      >
        <UserContext.Provider
          value={{
            user,
            updateUser,
          }}
        >
          <Layout>
            <Header className="header">
              <Link to="/">
                <div className="logo">
                  <img
                    src="https://engmastery.com/assets/images/logo1.png"
                    alt="logo"
                    style={{ width: "150px" }}
                  />
                </div>
              </Link>
              <div>
                <Button type="link" onClick={() => changeLanguage("en")}>
                  en
                </Button>
                -
                <Button onClick={() => changeLanguage("ar")} type="link">
                  ar
                </Button>
                {Authenticate.isAuthenticated && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip title={t("profile")}>
                      <Avatar
                        style={{
                          color: "#f56a00",
                          backgroundColor: "#fde3cf",
                          cursor: "pointer",
                        }}
                        onClick={() => history.push("/profile")}
                      >
                        {user &&
                          user.full_name &&
                          user.full_name[0].toUpperCase()}
                      </Avatar>
                    </Tooltip>
                    <h3 style={{ fontWeight: "bolder" }} onClick={logout}>
                      <LogoutOutlined /> {t("logout")}
                    </h3>
                  </div>
                )}
              </div>
            </Header>
            <Layout>
              <Switch>
                <Route path="/login">
                  <></>
                </Route>
                <PrivateRoute>
                  <SideMenu />
                </PrivateRoute>
              </Switch>
              <Layout style={{ padding: "24px" }}>
                <Content>
                  <Switch>
                    <Route path="/login">
                      {Authenticate.isAuthenticated && <Redirect to="/" />}
                      <LoginPage />
                    </Route>
                    {currentUser.permissions & (1 << 1) && (
                      <PrivateRoute path="/admin">
                        <Admin />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 2) && (
                      <PrivateRoute path="/teacher">
                        <Teacher />
                      </PrivateRoute>
                    )}

                    {currentUser.permissions & (1 << 3) && (
                      <PrivateRoute path="/users">
                        <Users />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 23) && (
                      <PrivateRoute path="/settings">
                        <Settings />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 22) && (
                      <PrivateRoute path="/welcome-page">
                        <WelcomePage />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 4) && (
                      <PrivateRoute path="/welcome-page">
                        <WelcomePage />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 5) && (
                      <PrivateRoute path="/static-pages">
                        <StaticPages />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 6) && (
                      <PrivateRoute path="/help-page">
                        <HelpPage />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 7) && (
                      <PrivateRoute path="/homePage">
                        <HomePage />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 8) && (
                      <PrivateRoute path="/level">
                        <Level />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 9) && (
                      <PrivateRoute path="/subscription">
                        <Subscription />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 10) && (
                      <PrivateRoute path="/subject-type">
                        <SubjectType />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 11) && (
                      <PrivateRoute path="/complains-type">
                        <ComplainsType />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 12) && (
                      <PrivateRoute path="/language">
                        <Language />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 13) && (
                      <PrivateRoute path="/country">
                        <Countries />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 14) && (
                      <PrivateRoute path="/city">
                        <Cities />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 15) && (
                      <PrivateRoute path="/old-notification">
                        <OldNotification />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 16) && (
                      <PrivateRoute path="/admin-notification">
                        <AdminNotification />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 17) && (
                      <PrivateRoute path="/rate">
                        <Rate />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 18) && (
                      <PrivateRoute path="/complains">
                        <Complains />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 19) && (
                      <PrivateRoute path="/adv">
                        <Adv />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 20) && (
                      <PrivateRoute path="/payment">
                        <Payment />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 21) && (
                      <PrivateRoute path="/conversation">
                        <Conversation />
                      </PrivateRoute>
                    )}
                    {currentUser.permissions & (1 << 22) && (
                      <PrivateRoute path="/coupons">
                        <Coupons />
                      </PrivateRoute>
                    )}
                    <PrivateRoute path="/profile">
                      <Profile />
                    </PrivateRoute>
                    {currentUser.permissions & (1 << 0) && (
                      <PrivateRoute path="/">
                        <Home />
                      </PrivateRoute>
                    )}
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </UserContext.Provider>
      </ConfigProvider>
    </I18nextProvider>
  );
}

export default App;
