import instance from './index';

export const listWelcome = () => {
  return instance.get('constant/welcome', {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
export const welcomePage = (id) => {
  return instance.get('constant/welcome/' + id, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const add = (data) => {
  return instance.post('constant/welcome_add', data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
export const edit = (data, id) => {
  return instance.post('constant/welcome_update/' + id, data, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};

export const deletePage = (id) => {
  return instance.post(`constant/welcome_delete/${id}`, null, {
    headers: {
      token: localStorage.getItem('token'),
    },
  });
};
